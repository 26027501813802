<template>
  <div class="bubbles">
    <transition-group name="zoom" appear>
      <MotherBubble
        v-for="mb in motherBubbles"
        :key="mb.title"
        :motherBubble="mb"
        @click="showOverlay(mb.slug)" />
    </transition-group>
    <transition-group name="bounce" appear>
      <Bubble
        v-for="(b, i) in bubbles"
        :key="i"
        :bubble="b"
        :bubbleRadius="bubbleRadius"
        :idx="i"
        :style="{ animationDelay: `${i * 0.15}s` }"
        @panstart="panstart"
        @pan="pan"
        @panend="panend"
        @tap="showOverlay(b.title)" />
    </transition-group>
    <ul class="nav-meta">
      <li @click="showOverlay('Impressum')">Impressum</li>
      <li @click="showOverlay('Datenschutz')">Datenschutz</li>
    </ul>
    <Overlay :show="overlay" :component="overlayComponent" @close="closeOverlay" />
  </div>
</template>


<script>
import { onMounted, ref } from 'vue'
import { gsap } from 'gsap'
import MotherBubble from '@/components/MotherBubble'
import Bubble from '@/components/Bubble'
import Overlay from '@/components/Overlay'

export default {
  name: 'Bubbles',

  components: {
    MotherBubble,
    Bubble,
    Overlay
  },

  setup() {
    const random = (min, max) => min + (max - min) * Math.random()
    const setupBubbles = () => {
      return [
        {
          radius: (window.innerWidth/100 * 8),
          angleStep: Math.PI*2 / 5,
          x: (window.innerWidth/100 * 20) - (window.innerWidth/100 * 8),
          y: (window.innerHeight/100 * 40) - (window.innerWidth/100 * 8),
          title: 'Gesundheits-<br>forschung',
          slug: 'Gesundheitsforschung',
          img: require('@/assets/img/gesundheitsforschung.jpg'),
          bubbles: [
            { title: 'HOGEMA', floatDuration: random(2, 6) },
            { title: 'EnErGie', floatDuration: random(2, 6) },
            { title: 'iRhythmics', floatDuration: random(2, 6) },
            { title: 'Onkother-H', floatDuration: random(2, 6) },
            { title: 'PriVileG-M', floatDuration: random(2, 6) }
          ]
        },
        {
          radius: (window.innerWidth/100 * 8),
          angleStep: Math.PI*2 / 4,
          x: (window.innerWidth/100 * 50) - (window.innerWidth/100 * 8),
          y: (window.innerHeight/100 * 70) - (window.innerWidth/100 * 8),
          title: 'Digitalisierung',
          slug: 'Digitalisierung',
          img: require('@/assets/img/digitalisierung.jpg'),
          bubbles: [
            { title: 'E-BRAiN', floatDuration: random(2, 6) },
            { title: 'DigiCare', floatDuration: random(2, 6) },
            { title: 'DIG-IT!', floatDuration: random(2, 6) },
            { title: 'NEISS', floatDuration: random(2, 6) }
          ]
        },
        {
          radius: (window.innerWidth/100 * 8),
          angleStep: Math.PI*2 / 5,
          x: (window.innerWidth/100 * 80) - (window.innerWidth/100 * 8),
          y: (window.innerHeight/100 * 40) - (window.innerWidth/100 * 8),
          title: 'Energie &<br>Life Science',
          slug: 'Energie-Life-Science',
          img: require('@/assets/img/science.jpg'),
          bubbles: [
            { title: 'Card-ii-Omics', floatDuration: random(2, 6) },
            { title: 'KoInfekt', floatDuration: random(2, 6) },
            { title: 'PePPP', floatDuration: random(2, 6) },
            { title: 'WETSCAPES', floatDuration: random(2, 6) },
            { title: 'Netz-Stabil', floatDuration: random(2, 6) }
          ]
        },
        {
          radius: (window.innerWidth/100 * 4),
          x: (window.innerWidth/100 * 10) - (window.innerWidth/100 * 4),
          y: (window.innerHeight/100 * 80) - (window.innerWidth/100 * 4),
          title: 'Infos',
          slug: 'Infos',
          img: require('@/assets/img/infos.jpg')
        },
        {
          radius: (window.innerWidth/100 * 4),
          x: (window.innerWidth/100 * 90) - (window.innerWidth/100 * 4),
          y: (window.innerHeight/100 * 80) - (window.innerWidth/100 * 4),
          title: 'Quiz',
          slug: 'Quiz',
          img: require('@/assets/img/quiz.jpg')
        }
      ]
    }

    const bubbleRadius = ref(null)
    const motherBubbles = ref(null)
    const bubbles = ref(null)

    const init = () => {
      bubbleRadius.value = window.innerWidth/100 * 2.75
      motherBubbles.value = setupBubbles()
      bubbles.value = [
        ...motherBubbles.value[0].bubbles,
        ...motherBubbles.value[1].bubbles,
        ...motherBubbles.value[2].bubbles
      ]
    }

    init()
    window.onresize = () => init()

    const overlay = ref(false)
    const overlayComponent = ref(null)
    const showOverlay = (component) => {
      if (!component) return
      overlay.value = true
      overlayComponent.value = component.toLowerCase().replace(/[^\w\s]/gi, '')
    }
    const closeOverlay = () => {
      overlay.value = false
      overlayComponent.value = null
    }

    let currentDeg = 0

    const animate = () => {
      requestAnimationFrame(animate)
      motherBubbles.value.forEach((mb) => {
        if (mb.bubbles) {
          mb.bubbles.forEach((bubble, idx) => {
            bubble.ghostX = mb.x + Math.cos(mb.angleStep * idx + currentDeg + 0.12) * mb.radius * 1.1 + mb.radius - bubbleRadius.value
            bubble.ghostY = mb.y + Math.sin(mb.angleStep * idx + currentDeg + 0.12) * mb.radius * 1.1 + mb.radius - bubbleRadius.value
            if (bubble.isDragging || bubble.isColliding) return
            bubble.x = mb.x + Math.cos(mb.angleStep * idx + currentDeg) * mb.radius * 1.1 + mb.radius - bubbleRadius.value
            bubble.y = mb.y + Math.sin(mb.angleStep * idx + currentDeg) * mb.radius * 1.1 + mb.radius - bubbleRadius.value
          })
        }
      })
      currentDeg += 0.0025
      if (currentDeg >= 360) currentDeg = 0
    }

    const tweenBack = (bubble) => {
      bubble.tween = gsap.to(bubble, {
        x: bubble.ghostX,
        y: bubble.ghostY,
        duration: 1,
        ease: "ease.out",
        onComplete: () => bubble.isColliding = false
      })
    }

    const checkBubbles = () => {
      bubbles.value.forEach((bubble) => {
        if (bubble.isColliding) tweenBack(bubble)
      })
    }
  
    const panstart = (idx) => {
      const bubble = bubbles.value[idx]
      if (bubble.tween) bubble.tween.kill()
      bubble.isDragging = true
    }

    const pan = (payload) => {
      const bubble = bubbles.value[payload.idx]
      bubble.isDragging = true
      bubble.x = payload.x
      bubble.y = payload.y

      bubbles.value.forEach((otherBubble) => {
        if (!otherBubble.isDragging) {
          const dx = bubble.x - otherBubble.x
          const dy = bubble.y - otherBubble.y
          const circleDiameter = bubbleRadius.value * 2
          const distanceBetweenCenters = Math.hypot(dx, dy)
          const areOverlapping = distanceBetweenCenters < circleDiameter
          if (otherBubble.tween) otherBubble.tween.kill()

          if (areOverlapping) {
            otherBubble.isColliding = true
            const overlapDistance = circleDiameter - distanceBetweenCenters
            const percentOverlap = overlapDistance / circleDiameter
            const halfPercent = percentOverlap * 0.5
            otherBubble.x -= dx * halfPercent
            otherBubble.y -= dy * halfPercent
            bubble.x += dx * halfPercent
            bubble.y += dy * halfPercent
          } else if (otherBubble.isColliding) tweenBack(otherBubble)
        }
      })
    }

    const panend = (idx) => {
      const bubble = bubbles.value[idx]
      bubble.tween = gsap.to(bubble, {
        x: bubble.ghostX,
        y: bubble.ghostY,
        duration: 1,
        ease: "elastic.out(0.4, 0.2)",
        onStart: () => checkBubbles(),
        onComplete: () => {
          bubble.isDragging = false
        }
      })
    }

    onMounted(() => animate())

    // expose to template
    return {
      motherBubbles, bubbles, panstart, pan, panend, overlay, overlayComponent, showOverlay, closeOverlay, bubbleRadius
    }
  }
}
</script>


<style scoped lang="scss">
.bubbles {
  position: absolute;
  width: 100%;
  height: 100%;
}

.nav-meta {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  li {
    margin: 0 15px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: color 250ms ease-in-out;

    &:hover {
      color: #ff7f56;
    }
  }
}
</style>
