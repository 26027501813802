<template>
  <div class="quiz">
    <h1>
      Testen Sie Ihr Wissen
      ({{ currentQuestion + 1 }}/{{ shuffledQuestions.length }})
    </h1>

    <h2 v-if="!finished">{{ shuffledQuestions[currentQuestion].question }}</h2>
    <h2 v-else>Geschafft!</h2>

    <div class="messages">
      <svg
          viewBox="0 0 128 128">
        <circle cx="64" cy="64" r="60"/>
        <path d="M59.11,76.19Q59,75.51,59,74.88c0-.42,0-.86,0-1.32a16,16,0,0,1,1.19-6.46,17,17,0,0,1,3-4.72,24,24,0,0,1,4-3.48q2.12-1.49,4-3a18,18,0,0,0,3-3.06,6.08,6.08,0,0,0,1.19-3.78,5.59,5.59,0,0,0-2.46-4.76q-2.47-1.78-7.65-1.78a14.72,14.72,0,0,0-4.84.76,24.34,24.34,0,0,0-4.08,1.83,25.88,25.88,0,0,0-3.31,2.21q-1.45,1.15-2.55,1.91L45,42.12a24.75,24.75,0,0,1,4.63-3.91,28.61,28.61,0,0,1,5.48-2.8,33.24,33.24,0,0,1,5.95-1.66,34,34,0,0,1,6-.55q9.26,0,14.4,3.87a12.36,12.36,0,0,1,5.14,10.41,17.12,17.12,0,0,1-1.32,7.18A16.23,16.23,0,0,1,82,59.5,28.73,28.73,0,0,1,77.59,63a39.35,39.35,0,0,0-4.33,3.27,16.41,16.41,0,0,0-3.36,4,11.36,11.36,0,0,0-1.36,5.86Zm-1.7,12.07a6.13,6.13,0,0,1,1.87-4.72,6.94,6.94,0,0,1,4.93-1.74,7.19,7.19,0,0,1,5.1,1.74,6.14,6.14,0,0,1,1.87,4.72A6.27,6.27,0,0,1,69.31,93a7.08,7.08,0,0,1-5.1,1.78A6.84,6.84,0,0,1,59.28,93,6.27,6.27,0,0,1,57.42,88.26Z"/>
      </svg>
      <transition-group
          name="fade">
        <span v-if="showResult && correctAnswer" key="correct">
          Das war richtig!
        </span>
        <span v-if="showResult && !correctAnswer" key="incorrect">
          Das war leider falsch!
        </span>
        <span v-if="finished" key="finished">
          Ihre Punkte: {{ score }} von {{ shuffledQuestions.length }}
        </span>
      </transition-group>
    </div>

    <ul
        class="answers"
        v-if="!finished">
      <li
          v-for="(item, i) in shuffledQuestions[currentQuestion].answers"
          :key="i"
          @click="answerQuestion(item, $event)"
          :class="{ 'correct': item.correct & showResult }">
        {{ item.answer }}
      </li>
    </ul>

    <button
        @click="restart()"
        v-if="finished">
      Neustart
    </button>
  </div>
</template>


<script>
import { ref } from 'vue'
import { questions } from '@/questions'

const shuffle = (array) => array.sort(() => 0.5 - Math.random())

export default {
  setup: () => {
    let busy = false
    const shuffledQuestions = ref(shuffle(questions).slice(0, 5))
    shuffledQuestions.value.forEach(q => q.answers = shuffle(q.answers))

    const currentQuestion = ref(0)
    const score           = ref(0)
    const showResult      = ref(false)
    const correctAnswer   = ref(false)
    const finished        = ref(false)

    const restart = () => {
      shuffledQuestions.value = shuffle(questions).slice(0, 5)
      shuffledQuestions.value.forEach(q => q.answers = shuffle(q.answers))
      currentQuestion.value   = 0
      score.value             = 0
      showResult.value        = false
      correctAnswer.value     = false
      finished.value          = false
    }

    const answerQuestion = (answer, event) => {
      if (busy) return
      busy             = true
      showResult.value = true
      
      event.target.style.boxShadow = '0 0 0 1pt #35335c'

      if (answer.correct) {
        correctAnswer.value = true
        score.value += 1
      }
      
      setTimeout(() => {
        busy                = false
        showResult.value    = false
        correctAnswer.value = false

        event.target.style.boxShadow = 'none'
        
        if (currentQuestion.value + 1 < shuffledQuestions.value.length) currentQuestion.value += 1
        else finished.value = true
      }, 2000)
    }

    return {
      shuffledQuestions,
      currentQuestion,
      answerQuestion,
      showResult,
      correctAnswer,
      finished,
      score,
      restart
    }
  }
}
</script>


<style lang="scss" scoped>
.quiz {
  height: 100%;
  overflow: auto;
  padding: 3rem 4rem;
  position: relative;
  color: #35335c;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba($color: #000, $alpha: 0.05);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba($color: #000, $alpha: 0.1);
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($color: #000, $alpha: 0.25);
  }
}

h1 {
  font-size: 1.25rem;
  margin: 0;
}

h2 {
  font-size: 1.75rem;
  color: #ff7f56;
  margin: 0.25rem 0 0 0;
}

svg {
  max-width: 240px;
  margin: 3rem auto;

  circle {
    fill: #ff7f56;
  }

  path {
    fill: #fff;
  }
}

.messages {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    position: absolute;
    background-color: rgba($color: #fff, $alpha: 0.75);
    font-weight: 700;
    padding: 20px;
    border-radius: 1rem;
    border: 4px solid #fff;
    font-size: 2.5rem;
    box-shadow: 0 0 2rem rgba($color: #000, $alpha: 0.25);
  }
}

ul.answers {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    background-color: rgba($color: #ff7f56, $alpha: 0.1);
    padding: 15px 15px 15px 64px;
    margin-top: 0.5rem;
    font-weight: 700;
    counter-increment: li-count;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    transition: all 250ms ease-in-out;

    &:hover {
      background-color: rgba($color: #ff7f56, $alpha: 0.25);
    }

    &::before {
      position: absolute;
      content: counter(li-count)'.';
      display: inline-block;
      width: 48px;
      height: 100%;
      background-color: #ff7f56;
      color: #fff;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 800;
    }

    &.correct {
      background-color: rgba($color: #81bb2f, $alpha: 0.25);

      &::before {
        background-color: #81bb2f;
      }
    }
  }
}

button {
  display: flex;
  margin: 0 auto;
  background-color: #35335c;
  color: #fff;
  border: none;
  padding: 20px 40px;
  font-size: 2rem;
  font-weight: 700;
  border-radius: 1rem;
  cursor: pointer;

  &:hover {
    background-color: lighten(#35335c, 10);
  }
}
</style>
