<template>
  <div class="content">
    <main>
      <h1>Impressum</h1>
      <p>
        Projektträger Jülich | Forschungszentrum Jülich GmbH
        <br>Wilhelm-Johnen-Straße, 52428 Jülich
      </p>
      <h2>Kontakt</h2>
      <p>
        <b>Tel.:</b> 02461 61-2626
        <br><b>Fax:</b> 02461 61-5837
        <br><b>E-Mail:</b> <a href="mailto:ptj@fz-juelich.de">ptj@fz-juelich.de</a>
        <br><b>De-Mail:</b> <a href="mailto:poststelle@ptj.de-mail.de">poststelle@ptj.de-mail.de</a>
        <br><a href="http://www.fz-juelich.de/ptj" target="_blank">www.fz-juelich.de/ptj</a>
      </p>
      <h2>Postanschrift</h2>
      <p>
        52425 Jülich
      </p>
      <p>
        Die Forschungszentrum Jülich GmbH als Trägerorganisation des Projektträgers Jülich ist eingetragen im Handelsregister des Amtsgerichts Düren Nr. HR B 3498
      </p>
      <p>
        <b>Umsatzsteuer-Id-Nr.</b> gem. § 27 a Umsatzsteuergesetz: DE 122624631
        <br><b>Steuer-Nr.:</b> 213/5700/0033
      </p>
      <h2>Geschäftsführung der Forschungszentrum Jülich GmbH</h2>
      <p>
        Prof. Dr.-Ing. Wolfgang Marquardt (Vorsitzender des Vorstands)
        <br>Karsten Beneke (Stellvertr. Vorsitzender des Vorstands)
        <br>Prof. Dr.-Ing. Harald Bolt
        <br>Prof. Dr. Frauke Melchior
      </p>
      <h2>Vorsitzender des Aufsichtsrats</h2>
      <p>
        Ministerialdirektor Volker Rieke
      </p>
      <h2>Leiterin des Projektträgers Jülich</h2>
      <p>
        Dr. Stephanie Bauer
      </p>
      <h2>Verantwortlicher nach § 18 Abs. 2 Medienstaatsvertrag (MStV):</h2>
      <p>
        Uwe Selig
      </p>
      <p>
        Projektträger Jülich
        <br>Nachhaltige Entwicklung und Innovation
        <br>Marine und maritime Forschung, Geowissenschaften und Schifffahrt (MGS)
        <br>Schweriner Str. 44 – 18069 Rostock
      </p>
      <h2>Copyright</h2>
      <p>
        Für die Internet-Seiten des Projektträgers Jülich in der Forschungszentrum Jülich GmbH liegen Copyright und alle weiteren Rechte beim Projektträger Jülich in der Forschungszentrum Jülich GmbH. Die Weiterverbreitung, auch in Auszügen, für pädagogische, wissenschaftliche oder private Zwecke ist unter Angabe der Quelle gestattet (sofern nichts anderes an der entsprechenden Stelle ausdrücklich angegeben). Eine Verwendung im gewerblichen Bereich ist ohne ausdrückliche Zustimmung des Forschungszentrums Jülich nicht gestattet. Bilder mit einem anderen Quellennachweis als Forschungszentrum Jülich/Projektträger Jülich sind für eine Nutzung durch Dritte nicht freigegeben.
      </p>
      <h2>Haftungsausschluss</h2>
      <h3>Inhalt der eigenen Seiten</h3>
      <p>
        Wir haben die Internet-Seiten sorgfältig zusammengestellt. Allerdings übernehmen wir keine Gewähr oder Haftung für die Aktualität, Vollständigkeit und Richtigkeit der angebotenen Informationen.
      </p>
      <h3>Links auf externe Web-Seiten</h3>
      <p>
        Die Internet-Seiten des Projektträgers Jülich enthalten Links auf die Web-Seiten Dritter. Diese Links auf die Web-Seiten Dritter stellen keine Zustimmung zu deren Inhalt dar. Der Projektträger Jülich hat keinen Einfluss auf die aktuelle oder zukünftige Gestaltung dieser Seiten. Wir übernehmen daher keine Haftung für die Verfügbarkeit oder den Inhalt solcher Web-Seiten und keine Haftung für Schäden, die aus der Nutzung solcher Inhalte entstehen.
      </p>
    </main>
  </div>
</template>


<style lang="scss" scoped>
a {
  color: #ff7f56;

  &:hover {
    color: #343354
  }
}
</style>
