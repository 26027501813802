<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/EnErGie.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">EnErGie Video</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/EnErGie_Gruppe.jpg`">
            <div class="caption">Kick-off Meeting - Verbund EnErGie 30.11.2018/ Bildnachweis: Universitätsmedizin Rostock</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/EnErGie_Auswertung.jpg`">
            <div class="caption">Auswertung von Immunfluoreszenz-Bildern / Bildnachweis: Antje Zülke/ Universitätsmedizin Rostock</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/EnErGie_Grafik.jpg`">
            <div class="caption">Zusammenhang zwischen Malnutrion, Entzündung und Sarkopenie / Bildnachweis: EnErGie/ Projektträger Jülich, Forschungszentrum Jülich GmbH</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/EnErGie_Analyse.jpg`">
            <div class="caption">Erhebung von Gesundheitsdaten / Bildnachweis: Antje Zülke/ Universitätsmedizin Rostock</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/EnErGie.jpg`">
            <div class="caption">Im Projekt EnErGie werden die Mechanismen für Mangelernährung erforscht / Bildnachweis: Antje Zülke/ Universitätsmedizin Rostock</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>EnErGie - Innovative Behandlungskonzepte für Menschen mit Mangelernährung</h1>
        <p>Mangelernährung (Malnutrition) betrifft in Deutschland ca. 20 bis 40 Prozent der stationären Patienten, wobei die Prävalenz in der Gastroenterologie mit ca. 30 Prozent die dritthäufigste ist. Mangelernährung tritt bei gastroenterologischen Patienten häufig infolge einer Leberzirrhose, einer chronischen Pankreatitis oder im Rahmen eines Kurzdarmsyndroms auf. Zahlreiche Studien belegen, dass die krankheitsassoziierte Mangelernährung mit erhöhten Komplikationsraten, höherer Morbidität und Mortalität sowie höheren Kosten in Verbindung steht. Zudem ist eine Mangelernährung für Patienten mit vermehrter Müdigkeit, körperlicher Leistungsschwäche und verringerter Lebensqualität verbunden.</p>
        <p>Krankheitsbedingte Malnutrition ist pathophysiologisch und konzeptionell eng an eine subklinische, milde, chronische Entzündungen gekoppelt. Systemische Entzündungsreaktionen tragen wesentlich zum Abbau von Muskelmasse bei, welcher durch krankheitsbedingte Anorexie und den damit verbundenen Gewichtsverlust sowie durch krankheitsbedingte körperliche Hypomotilität weiter beschleunigt werden kann. Das gemeinsame Auftreten einer verminderten Muskelfunktion („Kraft“) und Muskelmasse wird als Sarkopenie bezeichnet.</p>
        <p>Patienten mit gastroenterologischen Grunderkrankungen stellen in Mecklenburg-Vorpommern eine Hauptgruppe der von Mangelernährung und Sarkopenie Betroffenen dar. Das Projekt <b>EnErGie</b> adressiert in einem multimodalen Ansatz drei Kernprobleme der Mangelernährung im Kontext gastroenterologischer Erkrankungen:</p>
        <p>Erstens soll das derzeit noch sehr begrenzte mechanistische Verständnis der Malnutrition und Sarkopenie bei Leberzirrhose, chronischer Pankreatitis und Kurzdarmsyndrom vertieft werden. Gerade das parallele Studium dieser drei Krankheitsentitäten wird zu neuen Einblicken in die Pathophysiologie der krankheitsassoziierten Mangelernährung führen.</p>
        <p>Zweitens soll durch das Verbundprojekt ein ausgesprochen praxisrelevantes Problem der Ernährungsmedizin adressiert werden: EnErGie zielt auf die Entwicklung eines Methodensets ab, das sich für die Diagnostik und Verlaufskontrolle mangelernährter gastroenterologischer Patienten in einem Flächenland wie Mecklenburg-Vorpommern besonders eignet.</p>
        <p>Drittens soll das Projekt einen unmittelbaren Beitrag zu einer besseren Versorgung mangelernährter Patienten in Mecklenburg-Vorpommern leisten. Ein Hauptansatzpunkt wird hierbei in einer Übertragung von Konzepten einer intensivierten Ernährungsmedizin vom stationären in den ambulanten Bereich durch Erarbeitung eines wissenschaftlich fundierten Empfehlungskatalogs gesehen.</p>
        <p>In dem beantragten Verbund haben sich Wissenschaftlerinnen und Wissenschaftler aus den Universitätsmedizinen Rostock und Greifswald, der Hochschule Neubrandenburg und dem Leibniz-Institut für Nutztierbiologie Dummerstorf zusammengeschlossen, um gemeinsam ein Projekt aus dem Bereich der Ernährungsmedizin zu bearbeiten, das sowohl auf die Erforschung von Mechanismen als auch auf versorgungswissenschaftliche Aspekte ausgerichtet ist. Die spezifischen Expertisen der Projektpartner liegen auf den Gebieten der Ernährungswissenschaften, der klinischen Ernährungsmedizin und der experimentellen Forschung und ergänzen sich in einer Art und Weise, die für die Bearbeitung der Projektfragestellungen wirkliche Synergien und einen echten Mehrwert ergeben.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/EnErGie_Ussing.jpg`">
        <div class="caption">Ussing-Kammer zur funktionellen Charakterisierung von Darmepithel/ Bildnachweis: Antje Zülke/ Universitätsmedizin Rostock</div>
        <h3>Projekttitel:</h3>
        <h2>Enterale Ernährung bei Malnutrition durch Erkrankungen des Gastrointestinaltrakts: vom Grundlagenverständnis zum innovativen Behandlungskonzept</h2>
        <h3>Fördersumme:</h3>
        <p>ca. 2 Mio. Euro</p>
        <h3>Laufzeit:</h3>
        <p>01.10.2018-31.03.2022</p>
        <h3>Koordinator des Verbundes:</h3>
        <p>Prof. Georg Lamprecht, Universitätsmedizin Rostock, Zentrum für Innere medizin, Klinik II, Abteilung für Gastroenterologie und Endokrinologie</p>
        <h3>Projektpartner:</h3>
        <p>Prof. Robert Jaster/ Dr. Peggy Berlin, Universitätsmedizin Rostock, Zentrum für Innere Medizin, Klinik II, Abteilung für Gastroenterologie und Endokrinologie</p>
        <p>Prof. Markus M. Lerch/ Dr. Ali A. Aghdassi/ Dr. Simone Gärtner, Universitätsmedizin Greifswald, Klinik und Poliklinik für Innere Medizin A, Lehrstuhl für Gastroenterologie,Endokrinologie und Ernährungsmedizin</p>
        <p>Prof. Luzia Valentini, Hochschule Neubrandenburg, Fachbereich Agrarwirtschaft und Lebensmittelwissenschaften, Institut für evidenzbasierte Diätetik (NIED)</p>
        <p>Prof. Cornelia C. Metges, Leibniz-Institut für Nutztierbiologie (FBN), Institut für Ernährungsphysiologie</p>
        <p>Prof. Leif-Alexander Garbe, Hochschule Neubrandenburg, Lebensmitteltechnologie Fachbereich Agrarwirtschaft und Lebensmittelwissenschaften</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

