<template>
  <div class="start">
    <header>
      <div>
        <b>Exzellenzforschungsprogramm</b>
        <br><span>Mecklenburg-Vorpommern</span>
      </div>
      <img src="./assets/img/logos.jpg" />
    </header>
    <Grid :opacity="0.25" />
    <Bubbles />
  </div>
</template>

<script>
import { appHeight } from '@/tools.js'
import Grid from '@/components/Grid'
import Bubbles from './components/Bubbles'

window.addEventListener('resize', appHeight)
appHeight()

export default {
  name: 'App',
  components: {
    Grid,
    Bubbles
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/fonts';

* {
  box-sizing: border-box;
}

:root {
  --app-height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: calc(10px + 0.6vh);
  font-family: 'Overpass', sans-serif !important;
  text-rendering: optimizeLegibility;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: none;
  user-select: none;
}

*:active,
*:focus {
  outline: none;
  box-shadow: none;
}

#app {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  overflow: hidden;
  background: url('./assets/img/bg.jpg') no-repeat center center;
  background-size: cover;

  @media not all and (hover:hover) {
    height: var(--app-height);
  }
}

header {
  width: 100%;
  min-height: 9.5vh;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  background: #fff;
  padding: 10px 3rem;
  display: flex;
  align-items: center;
  color: #252525;
  font-weight: 600;
  font-size: 1.25rem;
  pointer-events: none;
  box-shadow: 0 0 10px rgba($color: #000, $alpha: 0.4);

  > div {
    border-left: 3px solid #b4dbeb;
    padding-left: 15px;
    padding-top: 4px;
  }

  span {
    font-weight: 300;
  }

  img {
    height: 5vh;
    margin-left: auto;
  }
}

:root {
  --swiper-theme-color: #4646a2;
  --swiper-pagination-color: #4646a2;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  &.swiper-pagination-hidden {
    opacity: 0;
  }
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px;
  left: 0;
  width: 100%;
}
/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
  .swiper-pagination-bullet {
    transform: scale(0.33);
    position: relative;
  }
  .swiper-pagination-bullet-active {
    transform: scale(1);
  }
  .swiper-pagination-bullet-active-main {
    transform: scale(1);
  }
  .swiper-pagination-bullet-active-prev {
    transform: scale(0.66);
  }
  .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33);
  }
  .swiper-pagination-bullet-active-next {
    transform: scale(0.66);
  }
  .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33);
  }
}
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  background: #000;
  opacity: 0.12;
  @at-root button#{&} {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none;
  }
  .swiper-pagination-clickable & {
    cursor: pointer;
  }
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}
.swiper-container-horizontal {
  > .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin: 0 4px;
    }
    &.swiper-pagination-bullets-dynamic {
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      .swiper-pagination-bullet {
        transition: 200ms transform, 200ms left;
      }
    }
  }
  &.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms right;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes float {
  0% {
    transform: perspective(600px) translateZ(10px);
  }
  100% {
    transform: perspective(600px) translateY(-10px) translateX(-10px) translateZ(20px);
  }
}
</style>
