<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Energie_Life_Science.jpg`">
            <div class="caption">Ein Team von Nachwuchswissenschaftlern wertet Untersuchungsergebnisse aus / Bildnachweise: Alexander Raths - stock.adobe.com</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>Energie / Life Science</h1>
        <p>Fünf interdisziplinäre Forschungsverbünde aus den Bereichen Medizin, Ökologie und Energie wurden in der ersten Runde des Exzellenzforschungsprogramms des Landes Mecklenburg-Vorpommern mit insgesamt 25,5 Millionen Euro gefördert. Die Mittel stammten aus dem Europäischen Sozialfonds (ESF) der Förderperiode 2014 bis 2020. Im März 2021 wurden die fünf Projekte nach einer Laufzeit von jeweils 51 Monaten mit vielversprechenden Ergebnissen abgeschlossen, die eine große gesellschaftliche Relevanz aufweisen und wichtige Impulse für strategisch bedeutsame Zukunftsfelder liefern.</p>
        <p>Ein wichtiges Ziel des Exzellenzforschungsprogramms ist es, jungen Wissenschaftlerinnen und Wissenschaftlern eine Perspektive zu geben und den Forschungsstandort Mecklenburg-Vorpommern zu stärken. Insgesamt 114 Postdoktoranden und Doktoranden sowie ca. 100 Masterstudierende waren an den fünf Forschungsvorhaben mit großer thematischer Bandbreite beteiligt: In den innovativen Projekten wurden lebensbedrohliche bakterielle Infektionen, Ursachen von Proteinfehlbildungen, Konzepte für infektionsresistente Herzklappenprothesen sowie Lösungen für stabilere Stromnetze und die Bedeutung wiedervernässter Moore in MV untersucht.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <h2>Folgende Verbünde werden gefördert:</h2><br>
        <p>Universität und Universitätsmedizin Greifswald, Universitätsmedizin Rostock, Friedrich-Loeffler-Institut, Bundesforschungsinstitut für Tiergesundheit: <br><b>„Aufklärung der Pathomechanismen baktoviraler Koinfektionen mit neuen biomedizinischen Modellen (KoInfekt)“</b><br>Team um Prof. Dr. Sven Hammerschmidt</p>
        <p>Universitätsmedizin und Universität Greifswald, Universitätsmedizin und Universität Rostock, Institut für ImplantatTechnologie und Biomaterialien e. V.: <br><b>„Card-ii-Omics Kardiovaskuläre Implantatentwicklung-Infektionen-Proteomics: Prävention, Diagnostik und Therapie von Implantatinfektionen (Card-ii-Omics)“</b><br> Team um Prof. Dr. Emil C. Reisinger, Ko-Koordinatorin: Prof. Dr. Barbara M. Bröker</p>
        <p>Universitätsmedizin und Universität Greifswald, Universitätsmedizin und Universität Rostock, Leibniz-Institut für Katalyse e.V.: <br><b>„Proteinfehlfaltung, ER-Stress und Proteindegradation – Entwicklung einer systematischen Pipeline für individualisierte Therapien bei erblichen Leber und Pankreaserkrankungen (PePPP)“</b><br>Team um Prof. Dr. Markus M. Lerch</p>
        <p>Universität Rostock und Universität Greifswald: <br><b>„Stoffumsetzungsprozesse an Moor- und Küstenstandorten als Grundlage für Landnutzung, Klimawirkung und Gewässerschutz (Wetscapes)“</b><br>Team um Prof. Nicole Wrage-Mönnig</p>
        <p>Universität Rostock, Universität Greifswald und der Fachhochschule Stralsund: <br><b>„Netzstabilität mit Wind- und Bioenergie, Speichern und Lasten unter Berücksichtigung einer optimalen Sektorkopplung (Netz-Stabil)“</b><br>Team um Prof. Hans-Günter Eckel</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

