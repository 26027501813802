<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/Card-ii-Omics.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Card-ii-Omics Video</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Card-ii-Omics_2.jpg`">
            <div class="caption">Anzucht von Mikroorganismen im Bioreaktor: Die Mikrobiologin Dr. Katja Kriebel und die Doktorandin Julia Fischer überprüfen die Anzucht von Bakterien in einem Bioreaktor, der zur Herstellung rekombinanter Proteine genutzt wird / Bildnachweis: Card-ii-Omics</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Card-ii-Omics_3.jpg`">
            <div class="caption">Aufbereitung biologischer Proben: Die Doktorandin Nicole Normann vergleicht die Produktion von Virulenzfaktoren des Erregers Staphylococcus aureus mittels SDS-PAGE / Bildnachweis: Card-ii-Omics</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Card-ii-Omics_Kick-Off-Meeting.jpg`">
            <div class="caption">Gruppenbild des Projekts Card-ii-Omics anlässlich des Kick-off Meetings / Bildnachweis: Card-ii-Omics</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Card-ii-Omics_TAVI.jpg`">
            <div class="caption">Transkatheter-Herzklappenprothese (TAVI) / Bildnachweis: Card-ii-Omics</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Card-ii-Omics_Grafik.jpg`">
            <div class="caption">Bildnachweis: Card-ii-Omics /  Projektträger Jülich, Forschungszentrum Jülich GmbH</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>Card-ii-Omics - Herzklappeninfektionen verhindern, erkennen, bekämpfen</h1>
        <p>Der Forschungsverbund <b>Card-ii-Omics</b> hat das wissenschaftliche Ziel, Infektionen von kardiovaskulären Implantaten, insbesondere Herzklappenprothesen und Transkatheter-Aortenklappen, durch die Beschichtung von Implantatoberflächen zu vermeiden. Darüber hinaus sollen neuartige proteombasierte Methoden zur frühzeitigen Diagnose von Herzklappeninfektionen entwickelt und erprobt werden. Dazu werden neue Konzepte für infektionsresistente minimalinvasive Herzklappenprothesen erforscht, welche die Anheftung von Bakterien unterbinden und somit eine Infektion verhindern. Weitere Maßnahmen dienen der frühzeitigen Diagnostik und Therapie sowie der Abschätzung des Infektionsrisikos von Implantatpatienten. Aufgrund der demografisch bedingten Zunahme des Bedarfs an Herzklappenprothesen ist das adressierte Problemfeld gesundheitspolitisch und wirtschaftlich von außerordentlicher Bedeutung.</p>
        <p>Das Projektkonsortium verknüpft Wissenschaftscluster der Universitäten und Universitätsmedizinen in Rostock und Greifswald und das Institut für ImplantatTechnologie und Biomaterialien e.V. in Warnemünde mit exzellenten Vorarbeiten auf den Gebieten der Implantatentwicklung, der Infektionsforschung und der Proteomik. Die Kombination der komplementären Expertisen der im Projekt beteiligten Partner bietet die Voraussetzungen für deutliche Fortschritte bei der Prävention und Therapie von Herzklappeninfektionen.</p>
        <p>Das Ziel der Partner am Standort Rostock ist die Entwicklung und Erprobung infektionsresistenter perkutaner Herzklappenprothesen. Der Infektiologe Prof. Reisinger besitzt gemeinsam mit dem Kardiologen Prof. Ince, dessen Expertise auf dem Gebiet der Transkatheter-Aortenklappen-Implantation weltweit anerkannt ist, die notwendige klinische Kompetenz für die Prophylaxe und Therapie der infektiösen Endokarditis. Prof. Grabow und Prof. Schmitz sind ausgewiesen auf dem Gebiet der Entwicklung kardiovaskulärer Stents und Herzklappen und deren Translation an der Schnittstelle zwischen Wissenschaft und Wirtschaft. Der Mikrobiologe Prof. Bahl ist spezialisiert auf die Herstellung von Proteinen, die der Beschichtung von Implantatoberflächen dienen sollen.</p>
        <p>Am Standort Greifswald liegt der Fokus auf der Diagnostik und Abschätzung des Infektionsrisikos von Implantatpatienten mit der Intention, neue präventive und therapeutische Ansätze zu entwickeln. Die Immunologin Prof. Bröker erforscht das menschliche Immunsystem und dessen Rolle bei der Abwehr von Bakterien, und der Kardiologe Prof. Felix untersucht die Besiedelung von beschichteten Materialoberflächen durch körpereigene Zellen. Für die Etablierung neuartiger proteombasierter Methoden zur Analyse von Patientenproben kann der Verbund auf die umfangreichen Kenntnisse und Kompetenzen der Proteomik-Forschenden Prof. Hecker, Prof. Völker und Prof. Riedel bauen.</p>
        <p>Card-ii-Omics ermöglicht die fach- und standortübergreifende Ausbildung des exzellenten wissenschaftlichen Nachwuchses in Medizin, Natur- und Technikwissenschaften. Die Qualifizierung der jungen Wissenschaftlerinnen und Wissenschaftler hat im Projekt einen hohen Stellenwert. Als wichtigste Maßnahme wurden wissenschaftliche Nachwuchsgruppen an den Standorten Rostock und Greifswald eingerichtet. Insgesamt werden in Card-ii-Omics zwei Nachwuchsgruppenleiterinnen, acht Postdocs, ein Arzt, neun Promovierende und 24 wissenschaftliche Hilfskräfte gefördert und individuell betreut.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/Card-ii-Omics_1.jpg`">
        <div class="caption">Der Ingenieur Dr. Michael Stiehm und die wissenschaftliche Hilfskraft Finja Borowski im Prüflabor für Herzklappenprothesen / Bildnachweis: Card-ii-Omics</div>
        <h3>Projekttitel:</h3>
        <h2>Card-ii-Omics Kardiovaskuläre Implantatentwicklung-Infektionen-Proteomics: Prävention, Diagnostik und Therapie von Implantatinfektionen</h2>
        <h3>Fördersumme:</h3>
        <p>ca. 5 Mio. Euro</p>
        <h3>Laufzeit:</h3>
        <p>01.01.2017-31.03.2021</p>
        <h3>Koordinator des Verbundes:</h3>
        <p>Prof. Dr. med. univ. Emil C. Reisinger, Universitätsmedizin Rostock, Zentrum für Innere Medizin II, Abteilung Tropenmedizin, Infektionskrankheiten und Nephrologie</p>
        <h3>Ko-Koordinatorin des Verbundes:</h3>
        <p>Prof. Dr. med. Barbara M. Bröker, Universitätsmedizin Greifswald, Institut für Immunologie und Transfusionsmedizin, Abteilung Immunologie</p>
        <h3>Projektpartner:</h3>
        <p>Prof. Hubert Bahl, Universität Rostock, Institut für Biowissenschaften</p>
        <p>Prof. Stephan Felix, Universitätsmedizin Greifswald, Klinik und Poliklinik für Innere Medizin B</p>
        <p>Prof. Niels Grabow, Universitätsmedizin Rostock, Institut für Biomedizinische Technik</p>
        <p>Prof. Uwe Völker/ Prof. Michael Hecker, Universitätsmedizin Greifswald, Interfakultäres Institut für Genetik und Funktionelle Genomforschung</p>
        <p>Prof. Hüseyin Ince, Universitätsmedizin Rostock, Zentrum für Innere Medizin I</p>
        <p>Prof. Katharina Riedel, Universität Greifswald, Fachbereich Biologie</p>
        <p>Prof. Klaus-Peter Schmitz, Institut für Implantat-Technologie und Biomaterialien e.V.</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

