const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

const toggleFullscreen = (elem) => {
  if (!document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement) {
    if (elem.requestFullscreen) elem.requestFullscreen()
    else if (elem.msRequestFullscreen) elem.msRequestFullscreen()
    else if (elem.mozRequestFullScreen) elem.mozRequestFullScreen()
    else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen(elem.ALLOW_KEYBOARD_INPUT)
  } else {
    if (document.exitFullscreen) document.exitFullscreen()
    else if (document.msExitFullscreen) document.msExitFullscreen()
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen()
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen()
  }
}

const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const isIOSDevice = () => !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)


module.exports = {
  appHeight,
  toggleFullscreen,
  isMobileDevice,
  isIOSDevice
}
