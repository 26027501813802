<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/Gesundheitsforschung_Schareck_A.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Prof. Wolfgang Schareck, Rektor der Universität Rostock und Vorsitzender des Kuratoriums Gesundheitswirtschaft</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/Gesundheitsforschung_Schareck_B.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Prof. Wolfgang Schareck, Rektor der Universität Rostock und Vorsitzender des Kuratoriums Gesundheitswirtschaft</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>Gesundheitsforschung</h1>
        <p>Die zweite Runde beim Exzellenzforschungsprogramm Mecklenburg-Vorpommerns setzt ganz auf Forschung für die Gesundheit: Mit 10 Millionen Euro sollen Nachwuchswissenschaftlerinnen und Nachwuchswissenschaftlern unterstützt werden, die sich in Forschungsverbünden weiter qualifizieren wollen. Die Förderung erfolgt aus dem Europäischen Sozialfonds (ESF) in der EU-Förderperiode 2014 bis 2020. Aus einem zweistufigen Bewerbungsverfahren werden 5 Verbünde gefördert, die bis zu 2 Millionen Euro an ESF-Mitteln erhalten. Bereits in der ersten themenoffenen Ausschreibungsrunde konnten sich drei Verbünde aus dem Bereich der Gesundheitsforschung im Wettbewerb mit ihren Projektideen durchsetzen. Diese werden hier ebenfalls thematisiert.</p>
        <p>Gefördert werden Forschungsprojekte mit den Schwerpunkten, die in den Gestaltungsfeldern des Masterplans Gesundheitswirtschaft liegen. Das sind LifeScience, Gesundheitsdienstleistungen, Gesundes Alter(n), Gesundheitstourismus und Ernährung für die Gesundheit.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <h2>Folgende Verbünde werden gefördert:</h2><br>
        <p>Universitätsmedizin Rostock, Universitätsmedizin Greifswald, Hochschule Neubrandenburg und Leibniz-Institut für Nutztierbiologie Dummerstorf: <br><b>„Enterale Ernährung bei Malnutrition durch Erkrankungen des Gastrointestinaltrakts: vom Grundlagenverständnis zum innovativen Behandlungskonzept (EnErGie)“</b><br>Team um Prof. Dr. Georg Lamprecht</p>
        <p>Universitätsmedizin Rostock, Universitätsmedizin Greifswald, Universität Rostock und Leibniz-Institut für Plasmaforschung und Technologie Greifswald: <br><b>„Entwicklungsplattform für innovative onkologische Therapien am Beispiel des häufigsten menschlichen Krebses – Hautkrebs (ONKOTHER-H)“</b><br>Team um Prof. Dr. Steffen Emmert</p>
        <p>Universitätsmedizin Rostock, Universitätsmedizin Greifswald, Universität Rostock, Hochschule Wismar und Fraunhofer-Projektgruppe Extrakorporale Immunmodulation Rostock: <br><b>„Erforschung neuartige Ansätze zur Bereitstellung verbesserter Gewebeersatzmaterialien auf Basis der hydrostatischen Hochdruckbehandlung (HOGEMA)“</b><br>Team um Prof. Dr. Rainer Bader</p>
        <p>Universitätsmedizin Rostock, Universitätsmedizin Greifswald, Universität Rostock und Leibniz-Institut für Nutztierbiologie Dummerstorf: <br><b>„Programmierte Herzschrittmacherzellen zur in vitro Medikamententestung (iRhythmics)“</b><br>Team um Prof. Dr. Robert David</p>
        <p>Universitätsmedizin Greifswald, Universität Greifswald und Hochschule Neubrandenburg: <br><b>„Präventionsnetzwerk Vorpommern: Psychosoziale und neurobiologische Gesundheit von Schwangeren und jungen Müttern (PriVileG-M)“</b><br>Team um Prof. Dr. Hans Jörgen Grabe</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

