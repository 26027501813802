<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/Netz-Stabil_Eckel.mp4#t=0.5`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Prof. Dr. Hans-Günter Eckel, Sprecher Projekt Netz-Stabil</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/Netz-Stabil_Gierschner.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Dr. Sidney Gierschner, wissenschaftlicher Mitarbeiter Projekt Netz-Stabil</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Netz-Stabil_Projektfoto.jpg`">
            <div class="caption">Die Doktoranden Franz Pocksties und Felix Uster zeigen ein Netzfrequenz-Detektionssystem, Bildnachweis: Universität Rostock, Franz Plocksties, Felix Uster</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Netz-Stabil_Magdalena_Gierschner.jpg`">
            <div class="caption">Die Doktorandin Magdalena Gierschner bei der Eingabe von Daten, Bildnachweis: Universität Rostock, Alexander Schöley</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Netz-Stabil_Projektbeteiligte.jpg`">
            <div class="caption">Gruppenfoto der Projektbeteiligten im Forschungsverbund Netz-Stabil, Bildnachweis: Universität Rostock, M. Rach</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Netz-Stabil_Sidney_Gierschner.jpg`">
            <div class="caption">Projektmitarbeiter Dr. Sidney Gierschner sowie die Doktorandinnen Magdalena Gierschner und Nastaran Fazli zeigen Demonstratoren für Mehrpunktumrichter im Schutzkleinspannungsbereich, Bildnachweis: Universität Rostock, David Hammes</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Netz-Stabil_Grafik.jpg`">
            <div class="caption">Netz-Stabil /  Projektträger Jülich, Forschungszentrum Jülich GmbH</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>Netz-Stabil - Stabile Energienetze mit regenerativen Energien und die Einbindung von Speichern</h1>
        <p>Das Projekt <b>Netz-Stabil</b> widmet sich den Problemen der Netzstabilität durch die Einspeisung regenerativer Energien und verfolgt dabei zwei Zielrichtungen. Zum einen werden Themen mit besonderem Bezug zu Mecklenburg-Vorpommern bearbeitet, um der Landespolitik sinnvolle Lösungswege aufzuzeigen: Das umfasst Untersuchungen zum netzdienlichen Betrieb von Biogasanlagen, zur optimierten Sektorkopplung, zur regionalen Einbindung von Wärmespeichern und zum notwendigen Netzausbau. Zum anderen werden neue innovative Forschungsbereiche bearbeitet. Darunter fallen die Entwicklung regelungstechnischer Konzepte für den Alleinbetrieb von Windenergieanlagen und die Optimierung dynamischer Netzstabilisierung durch leistungselektronisch angekoppelte Lasten. Fragestellungen zu Systemdienstleistungen durch Windenergie helfen, diesen Wissenschaftszweig in Mecklenburg-Vorpommern zu stärken. Neben den ingenieurwissenschaftlichen Themen werden regulatorische Rahmenbedingungen, kommerzielle Anreizsysteme und ethische Fragestellungen betrachtet.</p>
        <p>Die Grundlage aller wissenschaftlichen Untersuchungen im Konsortium bilden die technischen Anforderungen für den stabilen Betrieb des Verbundnetzes. Es müssen die Anforderungen an Erzeuger und Verbraucher definiert und der notwendige Netzausbau in Mecklenburg-Vorpommern evaluiert werden. Hierbei werden Bedarfe aus Netzsicht und Potentiale aus Verbrauchersicht in enger Zusammenarbeit der Partner diskutiert.</p>
        <p>Für die Netzstabilität spielen die regenerativen Erzeuger und die Einbindung von Speichern eine wichtige Rolle. Im Fokus stehen Stabilitätsprobleme der Umrichterregelung von Windenergieanlagen in leistungselektronisch dominierten Netzen und Rückwirkungen auf die Anlage selbst durch den netzdienlichen Betrieb. Der gezielte Einsatz von Biomasse- und thermischen Anlagen kann zu einem Ausgleich der fluktuierenden Einspeisung von Windenergie beitragen. Der mögliche Beitrag von Speichern zu dynamischen Systemdienstleistungen und die konkrete Realisierbarkeit in Mecklenburg-Vorpommern werden in engem Zusammenhang mit der Thematik der Umrichterstabilität evaluiert.</p>
        <p>Die Möglichkeiten der optimierten Vernetzung der elektrischen Energieversorgung mit den Sektoren Wärme und Kraftstoffe/Stoffe werden untersucht und ein vereinfachtes Energieflussmodell erstellt. Hiermit können unterschiedliche Betriebsstrategien und Erweiterungsmöglichkeiten durch Speicher und Kopplungstechnologien aufgezeigt werden. Dies erfordert eine enge Verzahnung der Partner. Eine Identifizierung und Analyse der rechtlichen Hemmnisse für eine optimale Sektorkopplung wird mit dem Schwerpunkt für Mecklenburg-Vorpommern durchgeführt. Weitere ökonomische, rechtliche und institutionelle Rahmenbedingungen werden übergreifend mit allen Partnern bearbeitet. In diesem Zusammenhang erfolgt auch eine Diskussion theologisch-ethischer Fragen im Zusammenspiel mit solchen der Ingenieur-, Rechts-und Wirtschaftswissenschaften.</p>
        <p>Im Projekt sollen drei Postdocs, 15 Promovierende und mehr als 20 Studierende qualifiziert werden. Das vorrangige Ziel ist die Erreichung der Habilitation, Promotion bzw. des Masterabschlusses durch die Bearbeitung von Fragestellungen der Netzstabilisierung. Durch wissenschaftliche Publikationen sollen die Resultate nach außen getragen werden. Zudem sollen die Erkenntnisse zu projektbegleitenden bzw. Folgevorhaben insbesondere mit Firmen aus Mecklenburg-Vorpommern führen.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/Netz-Stabil_Pruefstand_Seitenleiste.jpg`">
        <div class="caption">Prüfstand eines netzstabilisierenden Umrichters für Windenergieanlagen, Bildnachweis: Universität Rostock, M. Rach</div>
        <h3>Projekttitel:</h3>
        <h2>Netzstabilität mit Wind- und Bioenergie, Speichern und Lasten unter Berücksichtigung einer optimalen Sektorkopplung</h2>
        <h3>Fördersumme:</h3>
        <p>ca. 5 Mio. Euro</p>
        <h3>Laufzeit:</h3>
        <p>01.01.2017 – 31.03.2021</p>
        <h3>Koordinator des Verbundes:</h3>
        <p>Prof. Dr.-Ing. Hans-Günter Eckel, Universität Rostock, Fakultät für Informatik und Elektrotechnik, Institut für Elektrische Energietechnik, Lehrstuhl für Leistungselektronik und Elektrische Antriebe</p>
        <h3>Projektpartner:</h3>
        <p>Prof. Michael Rodi, Universität Greifswald, Lehrstuhl für Öffentliches Recht, Finanzrecht, Umwelt- und Energierecht</p>
        <p>Prof. Uwe Ritschel, Universität Rostock, Stiftungslehrstuhl für Windenergietechnik</p>
        <p>Prof. Torsten Jeinsch, Universität Rostock, Lehrstuhl für Regelungstechnik</p>
        <p>Prof. Thomas Luschtinetz, Hochschule Stralsund, Institut für Regenerative EnergieSysteme IRES</p>
        <p>Prof. Egon Hassel, Universität Rostock, Lehrstuhl für Technische Thermodynamik</p>
        <p>Prof. Michael Nelles, Universität Rostock, Professur für Abfall- und Stoffstromwirtschaft</p>
        <p>Prof. Michael Bierhoff, Hochschule Stralsund, Fachgebiet Elektrotechnik und Informatik</p>
        <p>Prof. Dirk Timmermann, Universität Rostock, Institut für Angewandte Mikroelektronik und Datentechnik</p>
        <p>Prof. Frank-Hendrik Wurm, Universität Rostock, Lehrstuhl für Strömungsmaschinen</p>
        <p>Prof. Harald Weber, Universität Rostock, Lehrstuhl für Elektrische Energieversorgung</p>
        <p>Prof. Gesche Linde, Universität Rostock, Theologische Fakultät, Lehrstuhl für Systematische Theologie und Religionsphilosophie</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>
