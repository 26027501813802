<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/PriVileG-M.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">PriVileG-M Video</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/PriVileG_Team.jpg`">
            <div class="caption">Das Forscherteam des Projekts PriVileG-M / Bildnachweis: PriVileG-M</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/PriVileG_Steuerruder.jpg`">
            <div class="caption">Mädchen mit Steuerruder/ Bildnachweis: Verbund PriVileG-M/ privat</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/PriVileG_Grafik.jpg`">
            <div class="caption">Pränataler Stress und seine Auswirkungen/ Bildnachweis: PriVileG-M/ Projektträger Jülich, Forschungszentrum Jülich GmbH</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>PriVileG-M - Stärkung der psychischen Gesundheit von Schwangeren und jungen Müttern</h1>
        <p>Epidemiologische Daten aus Deutschland zeigen, dass rund 44 Prozent der Schwangeren unter mindestens einer psychischen Erkrankung leiden. Vorgeburtliche Depressionen und Ängste kommen am häufigsten vor. Das Projekt <b>PriVileG-M</b> beschäftigt sich mit der psychischen Gesundheit von Schwangeren und jungen Müttern in Vorpommern. Im Fokus stehen die Auswirkungen des mütterlichen Stresses auf das (ungeborene) Kind – die sogenannte transgenerationale Übertragung. Dieser pränatale Stress kann weitreichende Folgen haben, die sich langfristig auf die Entwicklung des Kindes auswirken und die Wahrscheinlichkeit einer späteren psychischen Erkrankung erhöhen können. Das betrifft unter anderem das Potenzial von Defiziten in der schulischen, sozialen und psychischen Entwicklung des Kindes.</p>
        <p>Eine psychische Erkrankung eines Elternteils, insbesondere der Mutter, gilt als Risikofaktor für eine Kindeswohlgefährdung. Dazu zählen beispielsweise körperliche oder emotionale Vernachlässigung, Erschöpfung sowie Überforderung oder Ängste der Mutter. Hierdurch können Mütter oft nicht mehr adäquat auf die Bedürfnisse ihres Kindes reagieren, was zu Problemen in der Mutter- Kind-Beziehung führen kann.</p>
        <p>Im Rahmen einer randomisiert-kontrollierten Studie sollen die werdenden Mütter psychotherapeutische und telemedizinische Unterstützung erhalten. Zusätzlich werden den Frauen und Kindern Bioproben (zum Beispiel Speichel) entnommen, um Aussagen über neurobiologische Stresskomponenten (beispielsweise Cortisol) treffen zu können.</p>
        <p>Durch eine Verbesserung ihrer psychischen Gesundheit und eine Stärkung ihrer Mutterrolle wird die Sensitivität gegenüber ihrem Kind gesteigert. Durch eine ressourcenorientierte Unterstützung sollen der Mutter mehr Strategien zur Verfügung stehen, um psychische Belastungen im Alltag besser bewältigen zu können. Dadurch sind eine Verbesserung der Mutter-Kind-Kommunikation und somit eine Intensivierung der Mutter-Kind-Beziehung wahrscheinlicher.</p>
        <p>Die frühzeitige Intervention kann dem transgenerationalen Transfer präventiv entgegenwirken. Von großer Bedeutung hierbei ist der niederschwellige, also einfache Zugang zum Projekt. So werden bürokratische Hürden für die Teilnehmerinnen so gering wie möglich gehalten und es gibt die Möglichkeit, die therapeutischen Interventionen in ihrem Wohnraum durchzuführen. Diese sind individuell an die Bedürfnisse der Studienteilnehmerinnen angepasst und entsprechen so eher einer individualisierten psychotherapeutischen Kurzintervention.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/PriVileG_Seitenleiste.jpg`">
        <div class="caption">„Hände im Gras“, Bildnachweis: Verbund PriVileG-M/ privat</div>
        <h3>Projekttitel:</h3>
        <h2>Präventionsnetzwerk Vorpommern: Psychosoziale und neurobiologische Gesundheit von Schwangeren und jungen Müttern</h2>
        <h3>Fördersumme:</h3>
        <p>ca. 2 Mio. Euro</p>
        <h3>Laufzeit:</h3>
        <p>01.10.2018-31.03.2022</p>
        <h3>Koordinator des Verbundes:</h3>
        <p>Prof. Hans Jörgen Grabe, Universitätsmedizin Greifswald, Klinik und Poliklinik für Psychiatrie und Psychotherapie</p>
        <h3>Projektpartner:</h3>
        <p>Prof. Silke Schmidt, Universität Greifswald, Institut für Psychologie, Lehrstuhl Gesundheit und Prävention</p>
        <p>Prof. Marek Tadeusz Zygmunt, Universitätsmedizin Greifswald, Klinik und Poliklinik für Frauenheilkunde und Geburtshilfe</p>
        <p>Prof. Matthias Heckmann, Universitätsmedizin Greifswald, Neonatologie und Pädiatrische Intensivmedizin, Zentrum für Kinder- und Jugendmedizin</p>
        <p>PD Dr. Neeltje van den Berg, Universitätsmedizin Greifswald, Community Medicine, Abt. Versorgungsepidemiologie und Community Health</p>
        <p>Prof. Bedriska Bethke Hochschule Neubrandenburg, Fachbereich Gesundheit, Pflege, Management</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

