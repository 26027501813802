<template>
  <div
      class="bubble-container"
      :style="styles">
    <div
        class="bubble"
        :class="bubble.title.toLowerCase().replace('!', '')"
        ref="root"
        :style="{ animationDuration: bubble.floatDuration }">
      <span>
        {{ bubble.title }}
      </span>
    </div>
  </div>
</template>


<script>
import { ref, computed, onMounted } from 'vue'
import Hammer from 'hammerjs'

export default {
  name: 'Bubble',

  props: {
    bubble: Object,
    idx: Number,
    bubbleRadius: Number
  },

  setup(props, { emit }) {
    const root = ref(null)
    const styles = computed(() => {
      return {
        width: `${props.bubbleRadius * 2}px`,
        height: `${props.bubbleRadius * 2}px`,
        left: `${props.bubble.x}px`,
        top: `${props.bubble.y}px`
      }
    })
    
    onMounted(() => {
      const hammer = new Hammer(root.value)
      hammer.on('panstart', () => emit('panstart', props.idx))
      hammer.on('pan', (e) => {
        emit('pan', {
          idx: props.idx,
          x: e.center.x - props.bubbleRadius,
          y: e.center.y - props.bubbleRadius
        })
      })
      hammer.on('panend', () => emit('panend', props.idx))
      hammer.on('tap', () => emit('tap', props.idx))
    })

    return {
      root,
      styles
    }
  }
}
</script>


<style scoped lang="scss">
.bubble-container {
  position: fixed;
  z-index: 0;
}

.bubble {
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 0 0 0.2vw rgba($color: #fff, $alpha: 0.25), 0 0 20px rgba($color: #000, $alpha: 0.6);
  border: 3px solid #4646a2;
  animation: float 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate;
  background-size: 95% !important;
  // filter: grayscale(100%);
  cursor: pointer;
  transition: border 250ms ease-in-out;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  will-change: left;
  perspective: 1000;
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 1px);

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    top: -15px;
    border-radius: 50%;
    border: 3px dashed #fff;
    display: none;
    animation: spin 10s infinite linear;
  }

  &:hover {
    &::before {
      display: block;
    }
  }


  &.hogema {
    background: #fff url('../assets/img/logo-hogema.jpg') no-repeat center center;
  }

  &.energie {
    background: #fff url('../assets/img/logo-energie.jpg') no-repeat center center;
  }

  &.irhythmics {
    background: #fff url('../assets/img/logo-irhythmics.jpg') no-repeat center center;
  }

  &.privileg-m {
    background: #fff url('../assets/img/logo-privileg.jpg') no-repeat center center;
  }

  &.onkother-h {
    background: #fff url('../assets/img/logo-onkother.jpg') no-repeat center center;
  }

  &.e-brain {
    background: #fff url('../assets/img/logo-ebrain.jpg') no-repeat center center;
  }

  &.digicare {
    background: #fff url('../assets/img/logo-digicare.jpg') no-repeat center center;
  }

  &.dig-it {
    background: #fff url('../assets/img/logo-digit.jpg') no-repeat center center;
  }

  &.neiss {
    background: #fff url('../assets/img/logo-neiss.jpg') no-repeat center center;
  }

  &.card-ii-omics {
    background: #fff url('../assets/img/logo-cardiiomics.jpg') no-repeat center center;
  }

  &.koinfekt {
    background: #fff url('../assets/img/logo-koinfekt.jpg') no-repeat center center;
  }

  &.peppp {
    background: #fff url('../assets/img/logo-peppp.jpg') no-repeat center center;
  }

  &.wetscapes {
    background: #fff url('../assets/img/logo-wetscapes.jpg') no-repeat center center;
  }

  &.netz-stabil {
    background: #fff url('../assets/img/logo-netz-stabil.jpg') no-repeat center center;
  }

  span {
    position: absolute;
    font-size: calc(0.4rem + 0.5vw);
    bottom: -1.85vw;
    color: #fff;
    font-weight: 600;
    white-space: nowrap;
  }
}
</style>
