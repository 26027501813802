<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/DIG_IT_Wilmking.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Prof. Dr. Martin Wilmking, Sprecher Projekt DIG-IT!</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/DIG_IT_Kreyling.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Prof. Dr. Jürgen Kreyling, Leiter der Arbeitsgruppe "Experimentelle Pflanzenökologie" im Projekt DIG-IT!</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/DIG_IT_Doktorand_Peters.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Bo Peters, Doktorand im Projekt DIG-IT!</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/DIG_IT_Team.jpg`">
            <div class="caption">Gruppenbild der Projektpartner von DIG-IT! / Bildnachweis: Till Junker, Universität Greifswald</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/DIG_IT_Mesokosmenanlage_Uni.jpg`">
            <div class="caption">Die vollautomatisierte Mesokosmenanlage ermöglicht es, hochaufgelöste Daten zu Pflanzenwuchs und Wasserverbrauch zu erheben. Mit der integrierten automatisierten Bewässerung können eine Reihe verschiedener Umweltbedingungen simuliert werden / Bildnachweis: Universität Greifswald</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/DIG_IT_Mesokosmen_Fieldscales.jpg`">
            <div class="caption">In den rund 100 FieldScales der Mesokosmenanlage können Wasserstand oder Wasserzufur präzise gesteuert werden. Eine spezielle Kamera misst verschiedene Pflanzenparameter wie Höhe, Blattfläche, Blattneigung / Bildnachweis: Universität Greifswald</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/DIG_IT_Fledermaus.jpg`">
            <div class="caption">In einem Fledermauswinterquartier in MV wurden automatische Lichtschranken und Fotofallen installiert / Bildnachweis: DIG-IT!, Universität Greifswald</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/DIG_IT_Holzzell.jpg`">
            <div class="caption">Darstellung von Holzzellen der Weiß-Fichte (Picea glauca) mit einer speziellen Software / Bildnachweis: DIG-IT!, Universität Greifswald</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>DIG-IT! - Digitalisierung in den ökologischen Wissenschaften</h1>
        <p>Das Verbundprojekt <b>DIG-IT!</b> möchte durch den Einsatz von Machine-Learning-Methoden die Lösung wichtiger ökologischer Fragestellungen unterstützen. Bei der Erfassung und Beurteilung der Veränderungen der Biodiversität und Umwelt stellt die Auswertung von zeitlich und räumlich hochauflösenden Messdaten eine besondere Herausforderung dar. Insbesondere die Auswertung von Bilddaten, z. B. von Kamerafallen oder aus der Mikroskopie, ist oft zeitlich sehr aufwendig und erfordert spezielle Taxonomie- und Anatomiekenntnisse. Dadurch wird die Bearbeitung umfangreicher Probensätze limitiert bzw. die Auswertungen dauern sehr lange, so dass schnelle Reaktionen auf Veränderung in der Umwelt nicht möglich sind.</p>
        <p>DIG-IT! will die Chancen der Digitalisierung für die bessere und schnellere Aufarbeitung von Messdaten aus der Umwelt nutzen. Der zu bearbeitende breite Fragenkatalog umfasst Ökosystemdienstleistungen und Ökosystemstabilität unter Berücksichtigung des Klima- und Landnutzungswandels, Artenschutz und innovatives Umweltmonitoring. Ziel ist es, universell anwendbare Verfahren unter Verwendung selbstlernender Algorithmen („Deep Convolutional Neural Networks“) zu entwickeln. Damit soll ermöglicht werden, die große Menge an verfügbaren Bilddaten automatisiert und gegebenenfalls in Echtzeit auszuwerten. Durch diese immense Zeitersparnis können einerseits aktuelle Themen der ökologischen Forschung schneller bearbeitet werden und andererseits wird die Bearbeitung einiger ökologischer Fragestellungen erst durch deep learning Methoden überhaupt ermöglicht.</p>
        <p>Um diese Fragestellung bearbeiten zu können, wird Entwicklerexpertise für die automatisierte Analyse von Bilddaten (Fraunhofer-Institut für Graphische Datenverarbeitung aus Rostock / Biomathematik der Universität Greifswald) mit biologischem und ökologischem Fachwissen (Botanik, Landschaftsökologie, Zoologie von der Universität Greifswald) verknüpft. Dieser interdisziplinäre Forschungsansatz eröffnet neue Möglichkeiten in der Grundlagenforschung, als auch für die Lösung gesellschaftlich relevanter Fragestellungen in Mecklenburg-Vorpommern.</p>
        <p>In dem Verbundprojekt kooperieren Wissenschaftlerinnen und Wissenschaftler aus fünf Arbeitsgruppen der Universität Greifswald mit dem Fraunhofer-Institut für Graphische Datenverarbeitung aus Rostock. Zwei Postdoktoranden und sechs Promovierende aus diesen verschiedenen Fachgebieten bearbeiten gemeinsam Fragestellungen und erweitern somit fachübergreifend ihr Wissen. Weiterhin sollen 35 Studierende in die Forschungsarbeiten im Rahmen ihrer Ausbildung an den Universitäten eingebunden werden. So soll der Wissensgewinn aus dem Projekt sehr schnell in die Ausbildungsinhalte an der Universität transferiert werden. Zudem soll ökologisches Fachwissen mit digitalem Know-how verknüpft werden.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/DIG_IT_Wurzel_SegRoot_Segmentierung.jpg`">
        <div class="caption">Automatische Wurzelsegmentierung in einem Mesokosmen-Experiment / Bildnachweis: DIG-IT!, Universität Greifswald</div>
        <h3>Projekttitel:</h3>
        <h2>Digitalisierung natürlicher Komplexität zur Lösung gesellschaftlich relevanter ökologischer Probleme</h2>
        <h3>Fördersumme:</h3>
        <p>ca. 2,0 Mio. Euro</p>
        <h3>Laufzeit:</h3>
        <p>01.07.2019 – 31.08.2022</p>
        <h3>Koordinator des Verbundes:</h3>
        <p>Prof. Martin Wilmking, Universität Greifswald, Landschaftsökologie und Ökosystemdynamik</p>
        <h3>Projektpartner:</h3>
        <p>Prof. Jürgen Kreyling, Universität Greifswald, Experimentelle Pflanzenökologie</p>
        <p>Prof. Gerald Kerth, Universität Greifswald, Angewandte Zoologie und Naturschutz</p>
        <p>Prof. Uwe Freiherr von Lukas, Fraunhofer-Institut für Graphische Datenverarbeitung (IGD), Maritime Graphics</p>
        <p>Prof. Hans Joosten, Universität Greifswald, Moorkunde und Paläoökologie</p>
        <p>Prof. Mareike Fischer, Universität Greifswald, Biomathematik und Stochastik</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

