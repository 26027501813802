<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/iRhythmics.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">iRhythmics Video</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/iRhythmics_Screen.jpg`">
            <div class="caption">Auswertung von Aufnahmen mit dem Konfokalmikroskop Elyra PS.1 / Bildnachweis: iRhythmics</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/iRhythmics_Kickoff.jpg`">
            <div class="caption">Kick-off-Meeting des Forschungsverbunds iRhythmics im März 2019 im FBN Dummerstorf / Bildnachweis: Dr. N. K. Borowy, F. Mogwitz (FBN) / iRhythmics</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/iRhythmics_Mikroskop.jpg`">
            <div class="caption">Doktorandin Sophia Kussauer während einer Aufnahme mit dem Konfokalmikroskop Elyra PS.1/ Bildnachweis: iRhythmics</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/iRhythmics_Graphik.jpg`">
            <div class="caption">Bildnachweis: iRythmics /  Projektträger Jülich, Forschungszentrum Jülich GmbH</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>iRhythmics – Neuartige in vitro Tests zur Behandlung von Herzrhythmusstörungen</h1>
        <p>Herz-Kreislauferkrankungen sind die häufigste Todesursache in Deutschland, wobei Mecklenburg-Vorpommern im Vergleich der Bundesländer mit über 300 Todesfällen pro 100.000 Einwohner besonders stark betroffen ist. Die vollständige Regeneration des erkrankten Herzens ist nicht möglich. Darin liegt auch eine Hauptursache für Herzrhythmusstörungen. Diagnostik und Therapie von Herzrhythmusstörungen verursachen jährlich einen erheblichen Anteil der Gesundheitskosten. Durch die steigende Lebenserwartung und den dadurch bedingten kontinuierlich steigenden Anteil von älteren Menschen an der Gesamtbevölkerung wird sich dieser Trend weiter verstärken.</p>
        <p>Das Projekt <b>iRhythmics</b> adressiert die Generierung und Etablierung programmierter Herzschrittmacherzellen zum in vitro Test. Die Verfügbarkeit funktionalen Schrittmacherzellgewebes in vitro spielt eine wichtige Rolle bei der Medikamententestung kardioaktiver Substanzen einerseits und bei der Prüfung auf Verträglichkeit und Nebenwirkungen anderer Arzneien in Bezug auf die Herztätigkeit andererseits. Dadurch können Leitstrukturen zur Behandlung von Herzrhythmusstörungen in einem sehr frühen Stadium der Medikamentenentwicklung identifiziert werden. Des Weiteren ergeben sich wichtige Vorteile bei der Überprüfung der Sicherheit von verschiedensten Arzneimitteln bei gleichzeitiger Reduzierung von Tierversuchen. Hierdurch soll in vitro die Grundlage für die spätere Generierung eines Biologischen Schrittmachers in Patienten gelegt werden.</p>
        <p>Um die beschriebenen Fragestellungen zu bearbeiten, hat sich ein interdisziplinäres Team aus fünf führenden Wissenschaftlern der Universitätsmedizin Rostock, der Universität Rostock, der Universitätsmedizin Greifswald sowie des Leibniz-Instituts für Nutztierforschung Dummerstorf zusammengeschlossen. Als Koordinator des Verbundes führt Prof. Robert David die Expertisen der beteiligten Wissenschaftler zusammen. Prof. David (P1) leitet weiterhin die Arbeitspakete zum Forward Programming von Schrittmacherzellen sowie zur Entwicklung des Organoids der kardialen Reizleitung. Als Experte von der Universitätsmedizin Greifswald ist Prof. Brinkmeier (P2) für die elektrophysiologische Charakterisierung der programmierten Schrittmacherzellen zuständig. Frau Prof. Pützer (P3) ist verantwortlich für die Herstellung sämtlicher lentiviraler und adenoviraler Expressionskonstrukte, wobei erstere dem Forward Programming der Schrittmacherzellen zur Verfügung gestellt werden. Prof. Wolkenhauer (P4) leitet die systembiologische und bioinformatische Analyse der generierten Transkriptomdaten der Projektpartner sowie die darauf basierenden Netzwerkanalysen und Pharmakophormodelierungen. PD Dr. Höflich (P5) übernimmt sämtliche Transkriptomanalysen der programmierten murinen und humanen Schrittmacherzellen.</p>
        <p>Durch den Zusammenschluss der Verbundpartner ergibt sich ein hervorragendes Konsortium aus exzellenten Wissenschaftlern in Mecklenburg-Vorpommern. Die synergistischen Effekte, die sich für die einzelnen Forschungseinrichtungen und für das Land Mecklenburg-Vorpommern selbst ergeben, spiegeln sich einerseits in dem breiten Methodenspektrum der Partner wieder, von dem alle beteiligten Arbeitsgruppen bei der Bearbeitung der geplanten Arbeitspakete profitieren. Andererseits bieten die Verbundpartner durch ihre experimentellen Erfahrungen, den deutschlandweiten und internationalen Kooperationen sowie durch ihre wissenschaftliche Expertise eine ideale Basis, um junge Wissenschaftlerinnen und Wissenschaftler für aktuelle und auch zukünftige Forschungsprojekte zu qualifizieren.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/iRhythmics_Pipette.jpg`">
        <div class="caption">Zugabe von Calciumantagonisten auf die schlagenden Schrittmacherzellen auf dem MEA Chip / Bildnachweis: iRhythmics</div>
        <h3>Projekttitel:</h3>
        <h2>Programmierte Herzschrittmacherzellen zur in vitro Medikamententestung</h2>
        <h3>Fördersumme:</h3>
        <p>ca. 2 Mio. Euro</p>
        <h3>Laufzeit:</h3>
        <p>01.10.2018-31.12.2021</p>
        <h3>Koordinator des Verbundes:</h3>
        <p>Prof. Robert David, Universitätsmedizin Rostock, Klinik für Herzchirurgie, Referenz- und Translationszentrum für kardiale Stammzelltherapie</p>
        <h3>Projektpartner:</h3>
        <p>Prof. Heinrich Brinkmeier, Universitätsmedizin Greifswald, Institut für Pathophysiologie</p>
        <p>Prof. Brigitte Pützer, Universitätsmedizin Rostock, Institut für Experimentelle Gentherapie und Tumorforschung</p>
        <p>Prof. Olaf Wolkenhauer, Universität Rostock, Lehrstuhl für Systembiologie und Bioinformatik</p>
        <p>PD Dr. Andreas Höflich, Leibniz-Institut für Nutztierbiologie (FBN), Abteilung Signaltransduktion</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

