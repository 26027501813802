<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/WETSCAPES_Moennig.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Prof. Dr. Nicole Wrage-Mönnig, Sprecherin Projekt WETSCAPES</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/WETSCAPES_Guenther.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Dr. Anke Günther, wissenschaftliche Mitarbeiterin Projekt WETSCAPES</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/WETSCAPES_Kuestenlandschaft.jpg`">
            <div class="caption">Forschungsarbeiten auf den Karrendorfer Wiesen am südlichen Ufer des Greifswalder Boddens. Das Gebiet ist ein Beispielprojekt zur Renaturierung von Küsten-Überflutungsräumen in Mecklenburg-Vorpommern / Bildnachweis: WETSCAPES</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/WETSCAPES_Feldkampagne2.jpg`">
            <div class="caption">Gewinnung von Bodenproben mit dem Spaten / Bildnachweis: WETSCAPES</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/WETSCAPES_Feldkampagne.jpg`">
            <div class="caption">Verpacken von Bodenproben / Bildnachweis: WETSCAPES</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/WETSCAPES_Fliessgewaesser.jpg`">
            <div class="caption">Auf den Karrendorfer Wiesen wurden einem Rückbau des Deichs und des Binnenentwässerungssystems die natürlichen Überflutungsverhältnisse wieder hergestellt / Bildnachweis: Wetscapes</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/WETSCAPES_Moor1.jpg`">
            <div class="caption">WETSCAPES untersucht die Systeme Torf, Pflanzen und Wasser im Ökosystem Moor und deren Wechselwirkung mit den Nährstoffkreisläufen. / Bildnachweis: WETSCAPES</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/WETSCAPES_Torfprobe.jpg`">
            <div class="caption">Ein Forscherteam nimmt eine Torfprobe / Bildnachweis: WETSCAPES</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/WETSCAPES_Gruppenbild.jpg`">
            <div class="caption">Gruppenbild der am Projekt beteiligten Wissenschaftlerinnen und Wissenschaftler / Bildnachweis: WETSCAPES</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/WETSCAPES_Grafik.jpg`">
            <div class="caption">WETSCAPES /  Projektträger Jülich, Forschungszentrum Jülich GmbH</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>WETSCAPES - Grundlagen für eine schonende Nutzung von wiedervernässten Mooren und Küstenstandorten</h1>
        <p>Etwa 300.000 Hektar (13%) der Fläche Mecklenburg- Vorpommerns sind mit Mooren bedeckt. Damit prägen sie das Landschaftsbild, aber auch die Landnutzung. Abhängig vom Wasser- und Landmanagement tragen diese besonderen Ökosysteme erheblich zum Klima- und Gewässerschutz bei. Moore sind wichtige Kohlenstoffspeicher. Werden sie jedoch beispielsweise für die Landwirtschaft entwässert, wird Torf abgebaut und Kohlendioxid gelangt als schädliches Treibhausgas in die Atmosphäre. In Mecklenburg-Vorpommern stammt ein Drittel der Treibhausgasemissionen aus entwässerten Mooren. Durch ihre Wiedervernässung ließen sich voraussichtlich mindestens drei Megatonnen CO2 pro Jahr an Emissionen einsparen. Aus diesem Grund werden große Anstrengungen unternommen, Moorflächen wieder zu vernässen.</p>
        <p>Die wiedervernässten Moorflächen können jedoch nicht alle der agrarischen Nutzung entzogen werden. Die land- und forstwirtschaftliche Nutzung nasser Standorte und damit die Möglichkeit, Klima- und Gewässerschutz mit der Nutzung von Mooren zu verbinden, ist die Paludikultur. Paludikultur muss als ein wichtiges Element in der Energiewende betrachtet werden. So liefert die energetische Nutzung von Moorbiomasse Alternativen für fossile Rohstoffe, ohne in Konkurrenz um Flächen zur Nahrungsmittelproduktion zu treten. Durch Paludikultur entstehen „neue“ und noch weitgehend unverstandene Ökosysteme. Hier setzt <b>WETSCAPES</b> an: Das Projekt möchte wissenschaftliche Grundlagen für eine nachhaltige, schonende Bewirtschaftung von degradierten und wiedervernässten Moorstandorten erarbeiten.</p>
        <p>Ein interdisziplinäres Team aus Wissenschaftlern der Universitäten Rostock und Greifswald beschäftigt sich mit den Themenkomplexen Primärproduktion, Stoffumsetzungen und Stoffverlagerung, Gasaustausch und Torfbildung auf wiedervernässten, genutzten Moor-, Feucht- und Küstenstandorten. Dadurch wird es möglich sein, Indikatoren für nachhaltiges Landmanagement abzuleiten.</p>
        <p>Die Erkenntnisse über Wechselwirkungen in diesen Ökosystemen werden mit Hilfe der Fernerkundung auf die Fläche übertragen. In WETSCAPES wird die Expertise zur Erforschung der Moor- und Küstenökosysteme Mecklenburg-Vorpommerns gebündelt. Durch die Kombination der fachlichen Kompetenzen sollen Synergien erzeugt und ein „Leuchtturm“ der Moor- und Küstenforschung etabliert werden.</p>
        <p>Die Forschungsarbeiten erfolgen in enger interdisziplinärer Zusammenarbeit auf sechs ausgewählten Untersuchungsstandorten in Mecklenburg-Vorpommern, um dort die biotischen, abiotischen und biogeochemischen Prozesse sowohl in entwässerten als auch in wiedervernässten, landwirtschaftlich genutzten, Mooren, zu messen. Insgesamt sind 24 Wissenschaftlerinnen und Wissenschaftler in diesem Verbundprojekt beschäftigt und erhalten die Möglichkeit, sich durch Promotionen, Habilitationen und die Anfertigung von Fachartikeln zu qualifizieren. Weiterhin werden 33 Studierende in die Forschungsarbeiten eingebunden und können ihre Masterarbeit innerhalb dieses Wissenschaftsverbundes anfertigen.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/WETSCAPES_Seitenleiste.jpg`">
        <div class="caption">Messungen in einem Baum / Bildnachweis: WETSCAPES</div>
        <h3>Projekttitel:</h3>
        <h2>Stoffumsetzungsprozesse an Moor- und Küstenstandorten als Grundlage für Landnutzung, Klimawirkung und Gewässerschutz</h2>
        <h3>Fördersumme:</h3>
        <p>ca. 5 Mio. Euro</p>
        <h3>Laufzeit:</h3>
        <p>01.01.2017 – 31.03.2021</p>
        <h3>Koordinatorin des Verbundes:</h3>
        <p>Prof. Dr. Nicole Wrage-Mönnig, Universität Rostock, Agrar- und Umweltwissenschaftliche Fakultät, Grünland und Futterbauwissenschaften</p>
        <h3>Projektpartner:</h3>
        <p>Prof. Bernd Lennartz, Universität Rostock, Agrar- und Umweltwissenschaftliche Fakultät, Bodenphysik</p>
        <p>Prof. Peter Leinweber, Universität Rostock, Agrar- und Umweltwissenschaftliche Fakultät, Bodenkunde</p>
        <p>Dr. Gerald Jurasinski, Universität Rostock, Agrar- und Umweltwissenschaftliche Fakultät, Landschaftsökologie und Standortkunde</p>
        <p>Prof. Hans Joosten, Universität Greifswald, Mathematisch-Naturwissenschaftliche Fakultät, Moorkunde und Paläoökologie</p>
        <p>Prof. Tim Urich, Universität Greifswald, Mathematisch-Naturwissenschaftliche Fakultät, Bakterienphysiologie</p>
        <p>Prof. Jürgen Kreyling, Universität Greifswald, Mathematisch-Naturwissenschaftliche Fakultät, Experimentelle Pflanzenökologie</p>
        <p>Prof. Ralf Bill, Universität Rostock, Agrar- und Umweltwissenschaftliche Fakultät, Geodäsie und Geoinformatik</p>
        <p>Prof. Martin Wilmking, Universität Greifswald, Mathematisch-Naturwissenschaftliche Fakultät, Landschaftsökologie und Ökosystemdynamik</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

