<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/Onkother-H.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Onkother-H Video</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Onkother_H_Gruppenbild.jpg`">
            <div class="caption">Gruppenbild des Forschungsverbunds Onkother-H / Bildnachweis: B. Stroth</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Onkother_H_Witzke.jpg`">
            <div class="caption">Intraorale Plasmabehandlung an einem Patienten durch Dr. Witzke / Bildnachweis: K. Witzke</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Onkother_H_Screening.jpg`">
            <div class="caption">Hautkrebs-Screening/ Bildnachweis: S. Emmert</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Onkother_H_Steckbrief.jpg`">
            <div class="caption">Bildnachweis: Onkother-H / Projektträger Jülich, Forschungszentrum Jülich GmbH</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>Onkother-H - Entwicklung neuer innovativer Therapieformen bei Hautkrebs</h1>
        <p>Krebs ist weltweit die zweithäufigste Todesursache, nur übertroffen von Herz- und Kreislauferkrankungen. Hautkrebs ist mit den drei Tumorentitäten – kutanes Melanom, Basalzellkarzinom und Plattenepithelkarzinom – insgesamt die häufigste Krebserkrankung in Deutschland.</p>
        <p>Grundsätzlich stellen immer noch die Chirurgie, die Chemotherapie und die Strahlentherapie die drei klassischen therapeutischen Ansätze für Krebspatienten dar. Ziel des Verbundes ist es, eine translationale Entwicklungsplattform für neue Krebstherapien zu etablieren. Dazu haben sich die homogenen Hautkrebsentitäten, als Modelle etabliert. Am Beispiel des kutanen Melanoms und des Plattenepithelkarzinoms der Haut werden neu synthetisierte und pharmakologisch relevante Moleküle (small molecules) und die innovative Behandlung mit kaltem Atmosphärendruckplasma sowie deren Kombination von der Zellkultur bis in die in vivo Situation hinein evaluiert. Dabei werden von dem in jedem Bereich international exzellent ausgewiesenen Verbund insbesondere die folgenden Forschungsfelder bearbeitet:</p>
        <ul>
          <li>Synthese neuer pharmakologisch relevanter Moleküle - small molecules (Indirubin-N-Glykoside und Oxindolglykoside)</li>
          <li>Erschließung von kaltem Atmosphärendruckplasma als onkologische Therapieform und Kombination mit small molecules</li>
          <li>Anti-tumorigene Wirkung dieser neuen Therapieformen auf zellulärer Ebene (in vitro)</li>
          <li>Anti-tumorigene Wirkung dieser neuen Therapieformen in Tiermodellen (in vivo)</li>
          <li>Einsatz in Pilotstudien (zum Beispiel palliativer Einsatz von Atmosphärendruckplasma bei Patienten mit Plattenepithelkarzinomen im Kopf-/Halsbereich)</li>
        </ul>
        <p>Die in Mecklenburg-Vorpommern auf weltweit führendem Niveau entwickelte Behandlung mit kaltem Atmosphärendruckplasma, sowie small molecules, stellen ideale Ergänzungen dar, um in Kombination mit den gerade etablierten Immuntherapien eine weitere Sprunginnovation in der erfolgreichen Krebsbehandlung zu bilden. Einmal etabliert, wird der Verbund – als Plattform – diese und weitere Innovationen auch an anderen Krebsentitäten untersuchen und entwickeln können.</p>
        <p>Im Verbund ONKOTHER-H arbeiten interdisziplinär und transinstitutionell sechs Projektpartner bestehend aus acht Arbeitsgruppen aus zwei Universitäten und einer außeruniversitären Forschungseinrichtung in Mecklenburg-Vorpommern zusammen. Auf Basis einer über mehrere Jahre aufgebauten Vernetzung der einzelnen Partner und erfolgreich generierter Vorergebnisse lassen sich neue Therapieformen erwarten, die einer patentierten industriellen Nutzung zugeführt werden können. Die Etablierung neuer Therapieinnovationen in der Onkologie wirft auch ethische Fragestellungen bei Anwendungsstudien am Patienten auf. Mit der Einbindung des Alfried Krupp Wissenschaftskollegs Greifswald werden sowohl die Tierethik, als auch patientenorientierte Fragestellungen wissenschaftstheoretisch begleitet.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/Onkother_H_Steribank.jpg`">
        <div class="caption">Doktorandin Mirijam Schäfer an der Sterilbank/ Bildnachweis: L. Böckmann</div>
        <h3>Projekttitel:</h3>
        <h2>Entwicklungsplattform für innovative onkologische Therapien am Beispiel des häufigsten menschlichen Krebses - Hautkrebs</h2>
        <h3>Fördersumme:</h3>
        <p>2,2 Mio. Euro</p>
        <h3>Laufzeit:</h3>
        <p>01.10.2018-31.03.2022</p>
        <h3>Koordinator des Verbundes:</h3>
        <p>Prof. Steffen Emmert, Universitätsmedizin Rostock, Klinik und Poliklinik für Dermatologie und Venerologie</p>
        <h3>Projektpartner:</h3>
        <p>Prof. Burkhard Hinz/ Prof. Barbara Nebe, Universitätsmedizin Rostock, Institut für Pharmakologie und Toxikologie, Arbeitsbereich Zellbiologie</p>
        <p>Prof. Brigitte Vollmar, Universitätsmedizin Rostock, Rudolf-Zenker-Institut für Experimentelle Chirurgie</p>
        <p>Prof. Peter Langer, Universität Rostock, Institut für Chemie – Abteilung Organische Chemie</p>
        <p>Prof. Hans-Robert Metelmann, Universitätsmedizin Greifswald, Klinik und Poliklinik für Mund-Kiefer Gesichtschirurgie/Plastische Operationen</p>
        <p>Dr. Sander Bekeschus, Leibniz-Institut für Plasmaforschung und Technologie e. V. (INP), ZIK plasmatis</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

