<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Digitalisierung.jpg`">
            <div class="caption">Die Digitalisierung prägt immer mehr Bereiche auch in der Forschung / Bildnachweise: ipopba - stock.adobe.com</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>Digitalisierung</h1>
        <p>Ein wichtiges Zukunftsthema, die Digitalisierung, steht im Fokus des dritten Wettbewerbsaufrufs im Exzellenzforschungsprogramm des Landes Mecklenburg-Vorpommern. Die von einer unabhängigen Jury ausgewählten vier Forschungsverbünden werden mit insgesamt 9,25 Millionen Euro gefördert, um Fragestellungen der Digitalisierung in der Pflege, die Weiterentwicklung von Methoden der Künstlichen Intelligenz, neue Methoden der Digitalisierung im ökologischen Bereich sowie die Einsatzmöglichkeiten von humanoiden Therapierobotern zu untersuchen. Die Förderung erfolgt mit Mitteln aus dem Europäischen Sozialfonds (ESF) der Förderperiode 2014 bis 2020.</p>
        <p>In den vier Verbünden kooperieren sieben Forschungseinrichtungen des Landes. Einen Schwerpunkt der Vorhaben bildet die Qualifizierung und Ausbildung von Nachwuchswissenschaftlerinnen und Nachwuchswissenschaftler sowie Studierenden. Die Projekte sind stark interdisziplinär aufgestellt und vereinen Expertisen aus den Fachbereichen Medizin, Naturwissenschaften, Sozialwissenschaften und Ingenieurwesen in einem gemeinsamen Forschungsansatz. Die Arbeit im Verbund soll zudem dazu beitragen, die Leistungs- und Wettbewerbsfähigkeit der Hochschulen und außeruniversitären Forschungseinrichtungen in MV weiter zu verbessern.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <h2>Folgende Verbünde werden gefördert:</h2><br>
        <p>Universität Rostock und das Max-Planck-Institut für Plasmaphysik: <br><b>„Neuronale Extraktion von Informationen, Strukturen und Symmetrien aus Bildern (NEISS)“</b><br>Team um Prof. Dr. Roger Labahn</p>
        <p>Universität Greifswald und das Fraunhofer Institut für Graphische Datenverarbeitung in Rostock: <br><b>„Digitalisierung natürlicher Komplexität zur Lösung gesellschaftlich relevanter ökologischer Probleme (DIG-IT!)“</b><br>Team um Prof. Dr. Martin Wilmking</p>
        <p>Universitätsmedizin und Universität Greifswald, Universität Rostock und der Hochschule Neubrandenburg: <br><b>„Evidenz-basierte Robot-Assistenz in der Neurorehabilitation (E-BRAiN)“</b><br>Team um Prof. Dr. Thomas Platz</p>
        <p>Universität Rostock, Hochschule Neubrandenburg und das Deutschen Zentrum für Neurodegenerative Erkrankungen Standort Rostock/Greifswald: <br><b>„Digitales Trainieren von Pflege- und Gesundheitsmanagement (DigiCare)“</b><br>Team um Prof. Dr. Alke Martens</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

