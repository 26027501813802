<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/E-BRAiN.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Prof. Dr. med. Thomas Platz, Koordinator E-BRAiN</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/E-BRAiN_Bild_mit_Pepper_Crossout.jpg`">
            <div class="caption">Der humanoide Roboter Pepper beobachtet die Durchführung eines Tests auf dem Tablet Computer / Bildnachweis: IT und Medienzentrum Universität Rostock</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/E-BRAiN_Gruppenbild.jpg`">
            <div class="caption">Gruppenbild des Forscherteams im Projekt E-Brain / Bildnachweis: UMG/Janke</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/E-BRAiN_Pepper.jpg`">
            <div class="caption">Pepper ist ein humanoider Roboter eines kommerziellen Anbieters, der die Mimik und Gestik von Menschen analysieren kann. Bildnachweis: IT und Medienzentrum Universität Rostock</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/E-BRAiN_Schema.jpg`">
            <div class="caption">Bildnachweis: E-BRAiN /  Projektträger Jülich, Forschungszentrum Jülich GmbH</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>E-BRAIN - Evidenz-basierte Robot-Assistenz in der Neurorehabilitation</h1>
        <p>Das Verbundprojekt <b>E-BRAIN</b> untersucht die Einsatzmöglichkeiten von humanoiden Robotern als Therapie-Assistenz in der Neurorehabilitation. Schlaganfallbedingte Behinderungen („neuro-disabilities“) nehmen weltweit deutlich zu. Durch eine medizinische und im Umfang adäquate neurorehabilitative Behandlung können die Behinderungen nachhaltig reduziert und wieder mehr Selbständigkeit im Alltag der Patienten erreicht werden. Dies erfordert mehr Kapazitäten an Physiotherapeuten, Ergotherapeuten oder Neuropsychologen, welche momentan im Gesundheitswesen nicht zur Verfügung stehen. Humanoide Roboter als Therapie-Assistenz in der Neurorehabilitation haben das Potential, diesen notwendigen Bedarf mit abzudecken. Sie können die Behandlung durch Ärzte und Therapeuten unterstützen, mit dem Ziel, die schlaganfallbedingten Behinderungen zu reduzieren.</p>
        <p>Bisher gibt es den Einsatz von humanoiden Therapierobotern noch nicht und dieser soll zunächst in einem Forschungsansatz entwickelt werden. Dazu sind eine Reihe von Fragen zu klären, die eines genuin interprofessionellen Ansatzes bedürfen. Ohne diese Voraussetzungen kann eine seriöse und gesellschaftlich verantwortliche Entwicklung nicht realisiert werden.</p>
        <p>Zum einen gilt es in Zusammenarbeit von Medizin und Informationstechnologie, die Möglichkeiten einer digitalen Implementierung neurorehabilitativer Therapieverfahren (Motorik, visuelles System, Kognition) in einem Robot-System zu erforschen. Da ein humanoider Roboter ein „menschliches“ Aussehen hat, sprachlich und mimisch interagiert, ist seine Aktivität als „therapeutischer Assistent“ im Sinne einer „Beziehung“ zu gestalten und zu evaluieren. Dazu ist psychologische Kompetenz gefragt. Aber auch die Interaktionsphänomene, die spezifisch aus Schlaganfallbedingten Behinderungen resultieren, müssen medizinisch erforscht und berücksichtigt werden. Selbst wenn all dies gelingt, stellen sich Fragen zur Akzeptanz und der Präferenzen der Nutzer, der klinischen Effekte sowie der Kosten und damit der Relevanz für eine zukünftige medizinische Versorgung.</p>
        <p>In dem Verbundprojekt E-BRAIN verfolgen Wissenschaftlerinnen und Wissenschaftler aus Greifswald, Rostock und Neubrandenburg diesen zukunftsweisenden Forschungsansatz. Das interdisziplinäre Konsortium, bestehend aus Medizinern, Informatikern, Psychologen und Gesundheitsökonomen, bearbeitet die Fragestellung, wie bei wachsendem Bedarf und konstanten humanen Ressourcen eine umfangreiche medizinische Versorgung gewährleistet werden kann. Innerhalb des Verbundes arbeiten eine Ärztin und sechs Doktorandinnen und Doktoranden gemeinsam an dieser Fragestellung und werden dabei auch ihre individuellen fachlichen Qualifikationen verbessern. Weiterhin sollen 17 Studierende in die Forschungsarbeiten innerhalb ihrer Ausbildung an den Universitäten eingebunden werden. Durch diesen sehr interdisziplinären Forschungsansatz sollen die jungen Wissenschaftlerinnen und Wissenschaftler frühzeitig mit der Einbindung von digitalen Instrumenten in die Forschungsansätze ihrer Fachgebiete herangeführt werden. Für die Öffentlichkeit veranstaltet der Verbund eine Semestervorlesungsreihe im Alfried Krupp Wissenschaftskolleg Greifswald. Dabei werden gesellschaftlich relevante Fragen zur Thematik „Digitalisierung“ referiert und diskutiert. Die Fragestellungen dieses Projektes stellen wie viele andere Digitalisierung initiativen Beispiele eines technologischen Wandels in unserer Gesellschaft dar, in der die vielfältigen Wechselwirkungen zwischen Mensch und Technologie inzwischen sehr komplex geworden sind.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/E-BRAiN_Forscher.jpg`">
        <div class="caption">Das Arm-Fähigkeitstraining spielt in der Schlaganfall-Rehabilitation eine große Rolle und soll künftig noch effektiver gestaltet werden / Bildnachweis: Universitätsmedizin Greifswald</div>
        <h3>Projekttitel:</h3>
        <h2>Evidenzbasierte Robot-Assistenz in der Neurorehabilitation</h2>
        <h3>Fördersumme:</h3>
        <p>2,0 Mio. Euro</p>
        <h3>Laufzeit:</h3>
        <p>01.07.2019–30.09.2022</p>
        <h3>Koordinator des Verbundes:</h3>
        <p>Prof. Thomas Platz, Universitätsmedizin Greifswald, Neurorehabilitation/Core Unit Funktionelle Bildgebung</p>
        <h3>Projektpartner:</h3>
        <p>Prof. Thomas Platz Universitätsmedizin Greifswald, Neurorehabilitation</p>
        <p>Prof. Thomas Kirste Universität Rostock, Institut für Informatik/Mobile Multimediale Informationssysteme</p>
        <p>Prof. Peter Forbrig Universität Rostock, Institut für Informatik/Softwaretechnik</p>
        <p>Prof. Alfons Hamm Universität Greifswald, Institut für Psychologie</p>
        <p>Prof. Axel Mühlbacher Hochschule Neubrandenburg, Fachbereich Gesundheit, Pflege und Management</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

