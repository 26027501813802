<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/KoInfekt_Hammerschmidt.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Prof. Dr. Sven Hammerschmidt, Sprecher Projekt KoInfekt</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/KoInfekt_Kickoff.jpg`">
            <div class="caption">Gruppenfoto der Projektbeteiligten bei der Auftaktveranstaltung von KoInfekt am 19. Juni 2017 / Bildnachweis: Dr. Sylvia Kohler</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/KoInfekt_Grafik.jpg`">
            <div class="caption">Schematische Darstellung der in KoInfekt bearbeiteten Teilprojekte / Bildnachweis: KoInfekt / Projektträger Jülich, Forschungszentrum Jülich GmbH</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>KoInfekt - Neue biomedizinische Modelle für die Bekämpfung viraler und bakterieller Koinfektionen</h1>
        <p>Im Zeitalter des demographischen Wandels und der ansteigenden Antibiotikaresistenzen sind bakto-virale Koinfektionen für die alternde Gesellschaft ein immer größer werdendes Problem des Gesundheitssystems. Es fehlen geeignete Impfstoffe gegen virale und bakterielle Erreger, und eine Prognose zum Krankheitsverlauf ist aufgrund fehlender Biomarker schwierig. Daher werden neue Strategien für die Prävention, Erkennung, Prognose und Therapie dringend benötigt.</p>
        <p>Der Forschungsverbund <b>KoInfekt</b> möchte die Erreger-Wirt-Interaktionen, den Krankheitsverlauf und die Immunantwort des Menschen bei diesen Koinfektionen aufklären. Die in vivo-Studien erfolgen im Schwein und werden mit experimentellen Infektionsmodellen in der Maus verglichen, um das Schwein als humannahes biomedizinisches Infektionsmodell zu etablieren. So sollen Analysen und Prognosen ermöglicht und molekulare Biomarker für Koinfektionen etabliert werden. Des Weiteren sollen die Ergebnisse aus experimentellen Infektionsmodellen möglichst schnell in Kooperation mit Unternehmen aus Mecklenburg-Vorpommern zur klinischen Anwendung beim Menschen weiterentwickelt werden.</p>
        <p>Im Konsortium KoInfekt arbeiten Forschende verschiedener Fachdisziplinen der Universität Greifswald in Kooperation mit dem Friedrich-Loeffler-Institut, dem Bundesforschungsinstitut für Tiergesundheit und der Universitätsmedizin in Greifswald bzw. Rostock zusammen. Den Forschenden steht seit Dezember 2017 der Forschungsbau „Center for Functional Genomics of Microbes“ (C_FunGene) an der Universität Greifswald zur Verfügung.</p>
        <p>Im Forschungsverbund werden 23 Nachwuchswissenschaftlerinnen und Nachwuchswissenschaftler qualifiziert. Dazu wurde jeweils eine Nachwuchsgruppe an der Universität Greifswald und am Friedrich-Loeffler-Institut aufgebaut. Insgesamt sind fünf Postdocs und sechzehn Promovierende im Verbund involviert, die gemeinsam mit dem DFG-Graduiertenkolleg und den ansässigen Graduiertenakademien ausgebildet werden. Erstmalig werden hier Forschende in Human- und Tiermedizin gemeinsam qualifiziert. Außerdem werden Studierende in die Forschungsarbeiten eingebunden und erhalten die Möglichkeit, ihre Masterarbeit innerhalb dieses Wissenschaftsverbundes anzufertigen.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/KoInfekt_FB_Hesse.jpg`">
        <div class="caption">Bildungsministerin Birgit Hesse informierte sich 2018 bei einer Rundreise über KoInfekt / Bildnachweis: KoInfekt</div>
        <h3>Projekttitel:</h3>
        <h2>Neue biomedizinische Modelle für die Bekämpfung viraler und bakterieller Koinfektionen</h2>
        <h3>Fördersumme:</h3>
        <p>ca. 5 Mio. Euro</p>
        <h3>Laufzeit:</h3>
        <p>01.01.2017-31.03.2021</p>
        <h3>Koordinator des Verbundes:</h3>
        <p>Prof. Dr. rer. nat. Sven Hammerschmidt, Universität Greifswald, Center for Functional Genomics of Microbes, Interfakultäres Institut für Genetik und Funktionelle Genomforschung, Abteilung für Molekulare Genetik und Infektionsbiologie</p>
        <h3>Projektpartner:</h3>
        <p>Prof. Thomas C. Mettenleiter, Friedrich-Loeffler-Institut, Bundesforschungsinstitut für Tiergesundheit</p>
        <p>Prof. Uwe Völker, Universitätsmedizin Greifswald, C_FunGene, Abt. Funktionelle Genomforschung</p>
        <p>Prof. Barbara M. Bröker, Universitätsmedizin Greifswald, Abt. für Immunologie</p>
        <p>Prof. Michael Lalk, Universität Greifswald, Institut für Biochemie</p>
        <p>Prof. Katharina Riedel, Universität Greifswald, C_FunGene, Institut für Mikrobiologie</p>
        <p>Prof. Dörte Becher Universität Greifswald, C_FunGene, Institut für Mikrobiologie</p>
        <p>Prof. Ulrike Seifert, Universitätsmedizin Greifswald, Friedrich-Loeffler-Institut für Medizinische Mikrobiologie</p>
        <p>Prof. Bernd Kreikemeyer, Universitätsmedizin Rostock, Institut für Medizinische Mikrobiologie, Virologie und Hygiene</p>
        <p>Prof. Brigitte Müller-Hilke, Universitätsmedizin Rostock, Institut für Immunologie</p>
        <p>Prof. Jochen Schubert, Universitätsmedizin Rostock, Klinik und Poliklinik für Anästhesiologie und Intensivtherapie</p>
        <p>Prof. Tim Urich, Universität Greifswald, C_FunGene, Institut für Mikrobiologie</p>
        <p>Prof. Lars Kaderali, Universitätsmedizin Greifswald, C_FunGene, Institut für Bioinformatik</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

