<template>
  <div
      class="overlay"
      :class="{ active: show }">
    <transition
        appear
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut">
      <div
          class="backdrop"
          v-if="show" />
    </transition>
    <transition
        appear
        enter-active-class="animated bounceInDown"
        leave-active-class="animated bounceOutUp">
      <div
          class="container"
          v-if="show">
        <div class="overlay-content">
          <!-- Inactive components will be cached! -->
          <keep-alive>
            <component :is="component" />
          </keep-alive>
        </div>
        <button
        v-if="show"
        class="close"
        @click="$emit('close')" />
      </div>
    </transition>
    
  </div>
</template>


<script>
import gesundheitsforschung from '@/components/content/Gesundheitsforschung'
import hogema from '@/components/content/HOGEMA'
import energie from '@/components/content/EnErGie'
import irhythmics from '@/components/content/iRhythmics'
import onkotherh from '@/components/content/Onkother-H'
import privilegm from '@/components/content/PriVileG-M'
import digitalisierung from '@/components/content/Digitalisierung'
import ebrain from '@/components/content/E-BRAiN'
import digicare from '@/components/content/DigiCare'
import digit from '@/components/content/DIG-IT'
import neiss from '@/components/content/NEISS'
import energielifescience from '@/components/content/Energie-Life-Science'
import cardiiomics from '@/components/content/Card-ii-Omics'
import koinfekt from '@/components/content/KoInfekt'
import peppp from '@/components/content/PePPP'
import wetscapes from '@/components/content/WETSCAPES'
import netzstabil from '@/components/content/Netz-Stabil'
import infos from '@/components/content/Infos'
import quiz from '@/components/content/Quiz'
import impressum from '@/components/content/Impressum'
import datenschutz from '@/components/content/Datenschutz'

export default {
  props: {
    show: Boolean,
    component: String
  },

  components: {
    gesundheitsforschung,
    hogema,
    energie,
    irhythmics,
    onkotherh,
    privilegm,
    digitalisierung,
    ebrain,
    digicare,
    digit,
    neiss,
    energielifescience,
    cardiiomics,
    koinfekt,
    peppp,
    wetscapes,
    netzstabil,
    infos,
    quiz,
    impressum,
    datenschutz
  }
}
</script>


<style lang="scss" scoped>
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 10000;

  &.active {
    pointer-events: all;
  }
}

.backdrop {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba($color: #343354, $alpha: 0.85);
  backdrop-filter: blur(10px);
}

.container {
  position: absolute;
  z-index: 1000;
  width: 80%;
  max-width: 960px;
  height: 80%;
  box-shadow: 0 0 0 1vh rgba($color: #fff, $alpha: 0.25);
  border-radius: 0.25vh;
}

.overlay-content {
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: 0.5vh;
  background: #fff;
}

.close {
  position: absolute;
  width: 3.75rem;
  height: 3.75rem;
  top: -2rem;
  right: -2rem;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  background: #343354;
  border-radius: 50%;
  border: 4px solid #fff;

  &:hover {
    transform: rotate(90deg);

    &::before,
    &::after {
      background-color: #fff;
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 75%;
    background-color: #ff7f56;
    transition: all 250ms ease-in-out;
  }

  &::before {
    transform: rotate(45deg)
  }

  &::after {
    transform: rotate(-45deg)
  }
}

.bounceInDown {
  animation-delay: 500ms;
}

.fadeOut {
  animation-delay: 750ms;
}
</style>
