<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/Infos_Ministerin_Martin.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Ministerin Bettina Martin, Bildnachweis: Ministerium für Bildung, Wissenschaft und Kultur Mecklenburg-Vorpommern</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/Bildungsministerium.jpg`">
            <div class="caption">Ministerium für Bildung, Wissenschaft und Kultur Mecklenburg-Vorpommern / Bildnachweis: Ministerium</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>Exzellenzforschungsprogramm des Landes Mecklenburg-Vorpommern</h1>
        <p>Unter dem Oberbegriff Strukturfonds werden verschiedene Programme der Europäischen Union zusammengefasst, die zur Abschwächung des wirtschaftlichen und sozialen Gefälles zwischen hoch entwickelten und benachteiligten Regionen innerhalb Europas eingesetzt werden. Die drei großen europäischen Strukturfonds leisten damit einen wichtigen Beitrag zur strukturellen und wirtschaftlichen Entwicklung in Mecklenburg-Vorpommern. In der Förderperiode 2014 – 2020 stehen dem Bundesland knapp 2,3 Mrd. € aus allen europäischen Fonds zur Verfügung.</p>
        <p>Der Europäische Sozialfonds (ESF) ist einer dieser Strukturfonds. Schwerpunktmäßig zielt er darauf ab, die wirtschaftliche Leistungsfähigkeit des Landes zu unterstützen, mehr attraktive und werthaltige Beschäftigungsmöglichkeiten für Frauen und Männer zu schaffen und die soziale Teilhabe benachteiligter Gruppen in der Gesellschaft zu stärken. Die Umsetzung der genannten Ziele erfolgt über Investitionen in die schulische Bildung und die berufliche Aus- und Weiterbildung zur Entwicklung von Wissen und Innovation im Land. In der Förderperiode von 2014 - 2020 erhält Mecklenburg-Vorpommern knapp 385 Mio. € aus dem ESF.</p>
        <p>Für die Qualifizierung von Nachwuchswissenschaftler*innen im Land hat das Ministerium für Bildung, Wissenschaft und Kultur MV seit 2017 insgesamt 44,75 Mio. € aus dem ESF zur Verfügung gestellt. Eine große Herausforderung für die jungen Forschenden ist die Zusammenarbeit in interdisziplinären Teams: In den Forschungsverbünden sollen Naturwissenschaftler*innen, Mediziner*innen, Mathematiker*innen, Ingenieure*innen und Geisteswissenschaftler*innen ihre Kooperationsfähigkeit unter Beweis stellen. Dabei wird die wissenschaftliche Ausrichtung kontinuierlich und kritisch hinterfragt. Insbesondere die Vertreter*innen der „Technik-Ethik“ leisten mit ihren Diskussionsbeiträgen wichtige Impulse zur Relevanz und Bedeutung wissenschaftlicher Forschung in der Gesellschaft.</p>
        <p>14 Forschungsverbünde mit Schwerpunkten in der Medizin, Energie, Ökologie und der Digitalisierung werden im Rahmen des „Exzellenzforschungsprogramms des Landes – junge Nachwuchswissenschaftler*innen gesucht“ gefördert. In der Förderung befinden sich die beiden Universitäten des Landes in Rostock und Greifswald (inklusive der medizinischen Fakultäten), drei Hochschulen aus Wismar, Stralsund und Neubrandenburg sowie neun weitere außeruniversitäre Forschungseinrichtungen des Landes. Die beteiligten 100 Partner haben mehr als 210 Wissenschaftler*innen der unterschiedlichen Fachgebiete zur Bearbeitung der wissenschaftlichen Fragestellungen eingestellt. Vornehmlich sind das junge Promovierende mit dem angestrebten Abschluss einer Promotion und erfahrenere Postdoktoranden, die sich auf ihrem Fachgebiet weiterqualifizieren möchten. Darüber hinaus werden über 300 studentische Qualifikationsarbeiten betreut.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/Touch-Tisch-im-Foyer-BM.jpg`">
        <div class="caption">Touch Tisch im Foyer BM: Der Touch Tisch mit der multimedialen Darstellung des Exzellenzforschungsprogramms MV im Foyer des Ministeriums / Bildnachweis: PtJ</div>
        <br><br>
        <h2>Ministerium für Bildung, Wissenschaft und Kultur</h2>
        <br>
        <p>Das Ministerium für Bildung, Wissenschaft und Kultur hat die Aufgabe, günstige Bedingungen für die Bildung, die Wissensaneignung und das lebenslange Lernen zu schaffen, Lehre und Forschung auf ein exzellentes Niveau zu heben, den Austausch zwischen Wissenschaft und Wirtschaft zu intensivieren, die Kultur und den Sport zu stärken und zu fördern. Die Ministerin für Bildung, Wissenschaft und Kultur ist für Fragen der Schul- und Ausbildung, der Wissenschaft, der Kultur und des Sportes zuständig. Zum Geschäftsbereich des Bildungsministeriums (BM) gehört auch die Landeszentrale für politische Bildung.</p>
        <h3>Abteilung 3 - Wissenschaft und Forschung, Hochschulen</h3>
        <br>
        <p>Zum Zuständigkeitsbereich der Abteilung 3 gehören die Hochschulen und die Forschungseinrichtungen des Landes. Die Fachabteilung ist für die staatlichen Universitäten und Fachhochschulen, die Hochschule für Musik und Theater sowie die Universitätskliniken und die Studentenwerke zuständig. Dazu gehört eine hochschulübergreifende Entwicklungsplanung einschließlich der Bau- und Investitionsplanung im Sinne der Abstimmung der Profile der Hochschulen untereinander und im Verhältnis zu den außerhochschulischen Forschungseinrichtungen. Eine wichtige Aufgabe in diesem Zusammenhang ist die strategische Hochschulsteuerung und Qualitätssicherung. Sie wird durch das Steuerungsinstrument der Zielvereinbarungen wahrgenommen.</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

