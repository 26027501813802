<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/NEISS_Labahn.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Prof. Dr. Roger Labahn, Koordiantor des Forschungsverbundes NEISS</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/NEISS_Sperfeld.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Dr. Konrad Sperfeld, wissenschaftlicher Mitarbeiter im Forschungsverbund NEISS</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/NEISS_Lemke.mp4#t=0.5`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Marc Lemke, Doktorand im Forschungsverbund NEISS</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/NEISS_Kruse.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Björn Kruse, Doktorand im Forschungsverbund NEISS</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/NEISS_Gruppenbild.jpg`">
            <div class="caption">Doktorand Marc Lemke, Projektleiter Prof. Dr. Roger Labahn, Nachwuchsgruppenleiter Dr. Konrad Sperfeld, Doktorand Björn Kruse (v.l.) / Bildnachweis: Henning Kraudzun, PtJ</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/NEISS_Mathe_Campus.jpg`">
            <div class="caption">Campus Ulmenstraße der Universität Rostock / Bildnachweis: Universität Rostock</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/NEISS_Codebeispiel.jpg`">
            <div class="caption">Codebeispiel zur maschinellen Texterfassung von Uwe Johnsons „Mutmaßungen über Jakob“</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>NEISS - Anwendung neuronaler Netze in der Digitalisierung</h1>
        <p>Das Verbundprojekt NEISS fördert die Digitalisierung in diversen Wissensgebieten durch den Einsatz von Methoden der Künstlichen Intelligenz. Die gemeinsame Bearbeitung des Themas im Verbund beruht auf einer einheitlichen methodischen Basis: Aktuelle Technologien des Maschinellen Lernens werden für die Erfassung, Analyse und Extraktion verschiedener Typen von Informationen aus Bildern und Daten genutzt.</p>
        <p>Dem Projektkonsortium gehören Wissenschaftlerinnen und Wissenschaftler aus fünf Instituten der Universität Rostock sowie dem Greifswalder Institut für Plasmaphysik der Max-Planck-Gesellschaft an. Die breitgefächerten interdisziplinären Zielstellungen des NEISS-Projektes bleiben nicht auf Themen aus den Bereichen Mathematik – Informatik – Naturwissenschaften – Technik beschränkt, sondern umfassen auch die Geisteswissenschaften. Dort gibt es ein wiederum breites Spektrum wichtiger und sehr aktueller Bezüge zur Digitalisierung, die für die Bearbeitung im Verbund sinnvoll ausgewählt werden. Mit diesem Ansatz verstärkt das interdisziplinäre Konsortium die Clusterbildung im Themenfeld „Information und Kommunikation“ innerhalb der regionalen Innovationsstrategie des Landes Mecklenburg-Vorpommern.</p>
        <p>Das Konsortium ist stark interdisziplinär aufgestellt und bringt verschiedene Themenbereiche zusammen: theoretische und technologische Grundlagen aus der Mathematik und Informatik, Quantenoptik und Nanophotonik aus der Physik, Streubild-Daten Analysen aus der Physikalischen Chemie, Kernfusionsreaktionen aus der Plasmaphysik und Textmodellierung sowie Textkodierung aus der Germanistik.</p>
        <p>Für alle Anwendungsaufgaben sind die notwendigen Grundlagen und Algorithmen des Maschinellen Lernens erforderlich und verwendbar. Besonders starke Verbundeffekte sind bei Konzeption und Organisation von Querschnittsaufgaben zu erwarten. Diese sind u.a. durch gemeinsame methodische Grundlagen im Maschinellen Lernen bei den Lern- bzw. Trainingsdaten, den Lernverfahren sowie der Konzeption und Durchführung der Trainingsabläufe gegeben. Aufgrund der großen Breite und Differenziertheit der Fächer werden starke pragmatische Synergieeffekte und ein außerordentlich großer theoretischer Gewinn erwartet, die später auch für andere Anwendungen der Künstlichen Intelligenz nutzbar sind.</p>
        <p>In NEISS arbeiten im Rahmen der Nachwuchsgruppe von Dr. Konrad Sperfeld acht Wissenschaftlerinnen und Wissenschaftler an ihren Qualifikationsarbeiten. Weiterhin sollen acht Studierende in die Forschungsarbeiten innerhalb ihrer Ausbildung an der Universität eingebunden werden. Durch ein internes Studienprogramm mit regelmäßigen Forschungsseminaren soll die transdisziplinäre Ausbildung unterstützt und über die spezifische Wissensvermittlung fachübergreifendes Denken gefördert werden, um den Anforderungen der Digitalisierung in Industrie und Gesellschaft gerecht zu werden.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/NEISS_Studenten.jpg`">
        <div class="caption">Studierende der Mathematik / Bildnachweis: Universität Rostock</div>
        <h3>Projekttitel:</h3>
        <h2>Neuronale Extraktion von Informationen, Strukturen und Symmetrien aus Bildern</h2>
        <h3>Fördersumme:</h3>
        <p>ca. 2,3 Mio. Euro</p>
        <h3>Laufzeit:</h3>
        <p>01.07.2019 – 30.09.2022</p>
        <h3>Koordinator des Verbundes:</h3>
        <p>Prof. Roger Labahn, Universität Rostock, Institut für Mathematik</p>
        <h3>Projektpartner:</h3>
        <p>Prof. Stefan Scheel, Universität Rostock, Institut für Physik, Quantenoptik makroskopischer Systeme</p>
        <p>Prof. Joachim Wagner, Universität Rostock, Institut für Chemie, Physikalische Chemie</p>
        <p>Prof. Thomas Kirste, Universität Rostock, Institut für Visual and Analytic Computing, Lehrstuhl für Mobile Multimediale Informationssysteme</p>
        <p>Prof. Holger Helbig, Universität Rostock, Institut für Germanistik, Neuere deutsche Literaturwissenschaft des 20. Jahrhunderts</p>
        <p>Prof. Thomas Sunn Pedersen, Max-Planck-Gesellschaft, Institut für Plasmaphysik IPP, Teilinstitut Greifswald</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

