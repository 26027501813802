<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/HOGEMA.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">HOGEMA Video</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/HOGEMA-Sterilwerkbank.jpg`">
            <div class="caption">Doktoranden bei der Probenanalyse an der Sterilwerkbank / Bildnachweis: Anika Jonitz-Heincke</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/HOGEMA-Bedienung-HHD.jpg`">
            <div class="caption">Ein wissenschaftlicher Mitarbeiter des HOGEMA-Projekts bedient den Hochdruckreaktor / Bildnachweis: Anika Jonitz-Heincke</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/HOGEMA-Team.jpg`">
            <div class="caption">Team des Verbundvorhabens HOGEMA / Bildnachweis: Anika Jonitz-Heincke</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/HOGEMA-Beiraete.jpg`">
            <div class="caption">Mitglieder des wissenschaftlichen und industriellen Beirats / Bildnachweis: Anika Jonitz-Heincke</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/HOGEMA-Hochdruckreaktor.jpg`">
            <div class="caption">Nahaufnahme des Hochdruckreaktors / Bildnachweis: Anika Jonitz-Heincke</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/HOGEMA-Faszienentnahme.jpg`">
            <div class="caption">Entnahme von Faszien für die wissenschaftliche Arbeit / Bildnachweis: Anika Jonitz-Heincke</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/HOGEMA-Uebersicht.jpg`">
            <div class="caption">Bildnachweis: HOGEMA /  Projektträger Jülich, Forschungszentrum Jülich GmbH</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>HOGEMA - Innovative Verfahren zur Gewinnung und Aufbereitung von allogenem Transplantationsmaterial</h1>
        <p>Der menschliche Körper ist in der Lage, Weichgewebe - zum Beispiel Haut und Bindewebe - und Hartgewebe - wie etwa Knochen und Knorpel - nach verletzungs- und erkrankungsbedingtem Gewebeschäden bis zu einem gewissen Maß zu heilen. Jedoch nimmt die körpereigene Regenerationsfähigkeit mit zunehmendem Alter ab, sodass ein hoher Bedarf an Verfahren im Bereich des Gewebeersatzes besteht. Dabei wird das geschädigte Gewebe durch körpereigene bzw. allogene Strukturen oder synthetische Materialien ersetzt, welche idealerweise dessen Form und Funktionalität übernehmen.</p>
        <p>Der Forschungsverbund <b>HOGEMA</b> beschäftigt sich mit der Bereitstellung eines optimalen Transplantatmaterials aus allogenem (körperfremden) Gewebeersatz. Dieser hat für den Patienten den Vorteil, den operationsbedingten Risiken durch die Entnahme von autologem (körpereigenen) Gewebe nicht ausgesetzt zu sein. Dadurch kann der allogene Gewebeersatz einen wesentlichen Beitrag zur Verbesserung der Lebensqualität, zur Rückkehr in ein aktives Leben und zur sozialen Integration leisten.</p>
        <p>Ziel des Verbundvorhabens ist die Optimierung der hydrostatischen Hochdrucktechnologie sowie eine erweiterte Nutzungsmöglichkeit im Bereich Life Science. Diese Technologie bietet die Möglichkeit, Gewebe jeden Ursprungs schnell und schonend zu devitalisieren, ohne dabei wesentlichen Einfluss auf deren strukturelle Eigenschaften zu nehmen. Der hydrostatische Hochdruck (HHD) soll in HOGEMA dazu genutzt werden, neue Perspektiven für die Aufbereitung von humanen Allografts aus Stütz- (Knochen, Knorpel) und Bindegewebe (Faszie) zu schaffen. Dafür soll eine Technologieplattform geschaffen werden, um devitalisiertes allogenes Gewebe nach der HHD-Behandlung schonend und effizient von Zell- und Geweberesten zu befreien, um dieses als strukturell und biomechanisch stabiles Allograft-Transplantat im Bereich der Mund-Kiefer-Gesichtschirurgie, der Orthopädischen Chirurgie sowie der Hals-, Nasen und Ohrenheilkunde nutzen zu können. Zudem soll die Bereitstellung von devitalisiertem Gewebe für die Entwicklung und Etablierung von in vitro Modellsystemen ermöglicht werden, die anstelle etablierter Tiermodelle für eine Reihe von Fragestellungen in der Grundlagenforschung herangezogen werden können.</p>
        <p>Innerhalb des Verbundvorhabens HOGEMA wird die Universitätsmedizin Rostock zusammen mit der Universitätsmedizin Greifswald, der Universität Rostock, der Hochschule Wismar und dem Fraunhofer-Institut IZI zusammenarbeiten. Das Konsortium im Verbundvorhaben HOGEMA besteht aus Medizinern, Naturwissenschaftlern und Ingenieuren und beschäftigt sich in interdisziplinärer Zusammenarbeit mit einem hochgradig aktuellen wissenschaftlichen Thema aus dem Gebiet der Geweberegeneration und des Gewebeersatzes. Die entstehenden Vernetzungen werden auch über das Projekt hinaus langfristig zur translationalen Erforschung neuer Therapieformen bestehen und wissenschaftlich konkurrenzfähig bleiben. Daraus resultiert eine sehr gute Wettbewerbsposition um forschungsorientierte Nachwuchswissenschaftlerinnen und -wissenschaftler auf den oben genannten Gebieten.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/HOGEMA-Fraunhofer.jpg`">
        <div class="caption">Haitham Salti (Fraunhofer IZI) bei der Präparation einer Niere/ Bildnachweis: Fraunhofer IZI</div>
        <h3>Projekttitel:</h3>
        <h2>Erforschung neuartiger Ansätze zur Bereitstellung verbesserter Gewebeersatzmaterialien auf Basis der hydrostatischen Hochdruckbehandlung</h2>
        <h3>Fördersumme:</h3>
        <p>ca. 2 Mio. Euro</p>
        <h3>Laufzeit:</h3>
        <p>01.10.2018-31.03.2022</p>
        <h3>Koordinator des Verbundes:</h3>
        <p>Prof. Rainer Bader, Universitätsmedizin Rostock, Orthopädische Klinik und Poliklinik</p>
        <h3>Projektpartner:</h3>
        <p>Prof. Dr. Hermann Seitz, Universität Rostock, Lehrstuhl für Mikrofluidik</p>
        <p>Dr. Reinhold Wasserkort, Fraunhofer-Institut für Zelltherapie und Immunologie (IZI), Abteilung EXIM</p>
        <p>PD Dr. Michael Schlosser, Universitätsmedizin Greifswald,Klinik und Poliklinik für Chirurgie/ Institut für Medizinische Biochemie und Molekularbiologie</p>
        <p>Prof. Dr. Robert Mlynski, Universitätsmedizin Rostock, Klinik und Poliklinik für Hals-Nasen-Ohrenheilkunde, Kopf- und Halschirurgie „Otto Körner“</p>
        <p>Prof. Dr. Daniela Schwerdt, Hochschule Wismar, Maschinenbau/Verfahrens- und Umwelttechnik</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

