<template>
  <div class="grid-container" :style="{ opacity }">
    <svg class="grid" width="100%" height="100%">
      <defs>
        <pattern id="smallGrid" width="8" height="8" patternUnits="userSpaceOnUse">
          <path d="M 8 0 L 0 0 0 8" fill="none" stroke="rgba(255, 255, 255, 0.4)" stroke-width="0.25"/>
        </pattern>
        <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
          <rect width="80" height="80" fill="url(#smallGrid)"/>
          <path d="M 80 0 L 0 0 0 80" fill="none" stroke="rgba(255, 255, 255, 0.6)" stroke-width="0.5"/>
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill="url(#grid)" />
    </svg>
  </div>
</template>


<script>
export default {
  name: 'grid',
  props: {
    opacity: Number
  }
}
</script>


<style lang="scss" scoped>
@keyframes flickerall {
  0%    { opacity: 1;   }
  3%    { opacity: 0.5; }
  6%    { opacity: 1;   }
  7%    { opacity: 0.5; }
  8%    { opacity: 1;   }
  9%    { opacity: 0.5; }
  10%   { opacity: 1;   }
  100%  { opacity: 1;   }
}

.grid-container {
  pointer-events: none;
}

.grid {
  position: absolute;
  top: 0;
  left: 0;
  //animation: flickerall 6s infinite step-end;
}
</style>
