<template>
  <svg viewBox="0 0 21.1 40.7">
    <polyline points="0.4,40.4 20.4,20.4 0.4,0.4" />
  </svg>
</template>

<style scoped lang="scss">
svg {
  polyline {
    fill: none;
    stroke: var(--swiper-theme-color);
    stroke-width: 3;
    stroke-miterlimit: 10;
  }
}
</style>
