<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/PePPP_Lerch.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Prof. Dr. Markus Lerch, Sprecher Projekt PePPP</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/PePPP_Proben.jpg`">
            <div class="caption">Proben im Labor / Bildnachweis: Manuela Janke, Universitätsmedizin Greifswald</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/PePPP_Zellkultur.jpg`">
            <div class="caption">Die wissenschaftliche Mitarbeiterin Dr. Laura de Freitas Charma bearbeitet eine Zellkultur / Bildnachweis: Manuela Janke, Universitätsmedizin Greifswald</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/PePPP_Kick-Off.jpg`">
            <div class="caption">Gruppenbild des Projekts PePPP anlässlich des Kick-off Meetings/ Bildnachweis: PePPP/Universitätsmedizin Greifswald</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/PePPP_Grafik.jpg`">
            <div class="caption">ER-Stress und mögliche Wege zu dessen Reduktion/ Bildnachweis: Prof. Dr. Markus M. Lerch</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>PePPP - Individualisierte Therapien bei erblichen Erkrankungen der Leber und der Bauchspeicheldrüse</h1>
        <p>Das Endoplasmatische Retikulum (ER) ist ein Membransystem in der menschlichen Zelle und übernimmt wichtige Funktionen bei der Eiweißbildung. Eine Überbelastung – Stress – des ERs kann in der Entwicklung verschiedener Erkrankungen eine Rolle spielen. ER-Stress entsteht, wenn Eiweiße fehlerhaft produziert oder gefaltet werden und sich in den Zellen akkumulieren. Um Zellschäden zu verhindern, müssen diese „gespeicherten“ Proteine über spezielle Mechanismen wieder abgebaut oder entsorgt werden. Fehler in der Proteinfaltung oder in den ER-Stress-Abbau-Mechanismen wurden bereits für zum Beispiel Lebererkrankungen und für Formen der Bauchspeicheldrüsenentzündung (Pankreatitis) nachgewiesen. Andauernder ER-Stress führt zur Entstehung lokaler Entzündung, zur Schädigung von Gewebe und zu chronischen Organschäden.</p>
        <p>Im Projekt <b>PePPP</b> untersuchen Forscherteams aus Greifswald und Rostock nicht nur die ER-Stress-Mechanismen von erblichen Leber- und Pankreaserkrankungen näher untersuchen, sondern entwickeln auch neue therapeutische Wirkstoffe, die helfen sollen, der Entstehung von ER-Stress vorzubeugen und den Abbau fehlgebildeter Proteine in der Zelle zu unterstützen bzw. zu initiieren.</p>
        <p>Das Projekt vereint in einzigartiger Weise die Expertise von Forschergruppen aus Mecklenburg-Vorpommern auf dem Gebiet der Leber- und Bauchspeicheldrüsenerkrankungen. Die thematische Ausrichtung auf die Erforschung von ER-Stress und Proteinfehlfaltung als gemeinsame Krankheitsmechanismen von Leber- und Bauchspeicheldrüsenerkrankungen schafft die Synergien zur Identifikation von Wirkstoffen, die für beide Erkrankungen einen therapeutischen Nutzen haben und damit wirtschaftlich angewendet werden können.</p>
        <p>An den Kliniken in Greifswald (Prof. Markus M. Lerch) und Rostock (Prof. Dr. Dr. Andreas Hermann) werden mittels zellulärer Modelle sowie eines Tiermodelles ER-Stress-Mechanismen erforscht, die erblichen Leber- und Pankreaserkrankungen zu Grunde liegen können. Inwieweit dieser ER-Stress eine Ursache der chronischen Pankreatitis darstellt, soll in einem spezifisch generierten transgenen Mausmodell untersucht werden. Die Wissenschaftler an der Universität Rostock (Prof. Udo Kragl) sowie dem Leibniz-Institut für Katalyse e. V. (Prof. Matthias Beller) sind auf die Isolierung von Wirkstoffen aus der Natur bzw. deren Synthese und Derivatisierung spezialisiert. Gemeinsam wollen die Forschenden ER-Stress reduzierende Substanzen aus Pflanzenextrakten isolieren und synthetisch Wirkstoffe herstellen. Diese werden hinsichtlich ihrer Wirksamkeit getestet und überprüft, ob sie für die Entwicklung neuer Therapeutika geeignet sind. Weiterhin werden durch die Universitätsmedizin Greifswald (Prof. Sabine Salloch) in Zusammenarbeit mit der Deutschen Pankreashilfe e. V. die Sichtweise und Bedarfe betroffener Patienten und ihrer Angehörigen untersucht.</p>
        <p>Im Forschungsverbund sollen 18 Nachwuchswissenschaftlerinnen und Nachwuchswissenschaftler qualifiziert werden. Dazu wird jeweils eine Nachwuchsgruppe an der Universitätsmedizin Greifswald bzw. Universitätsmedizin Rostock etabliert. Beide Nachwuchsgruppenleiter werden ihre Kompetenzen in Bezug auf Drittmitteleinwerbung und Mitarbeiterführung weiter ausbauen sowie sich habilitieren. Weiterhin sind sechs Postdocs und zehn Promovierende im Forschungsverbund integriert. Zusätzlich werden 17 Studierende in die Forschungsarbeiten eingebunden und erhalten die Möglichkeit, ihre Masterarbeit oder medizinische Promotion innerhalb des Forschungsverbundes anzufertigen.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/PePPP_Manuel_Gronbach.jpg`">
        <div class="caption">Doktorand Manuel Gronbach von der Universität Rostock bei der Laborarbeit / Bildnachweis: Dr. Christina Oppermann, Universität Rostock</div>
        <h3>Projekttitel:</h3>
        <h2>Proteinfehlfaltung, ER-Stress und Proteindegradation - Entwicklung einer systematischen Pipeline für individualisierte Therapien bei erblichen Leber- und Pankreaserkrankungen</h2>
        <h3>Fördersumme:</h3>
        <p>ca. 5 Mio. Euro</p>
        <h3>Laufzeit:</h3>
        <p>01.01.2017-31.03.2021</p>
        <h3>Koordinator des Verbundes:</h3>
        <p>Prof. Dr. med. Markus M. Lerch, Universitätsmedizin Greifswald, Klinik und Poliklinik für Innere Medizin A</p>
        <h3>Projektpartner:</h3>
        <p>Prof. Dr. Julia Mayerle/ Dr. Frank Ulrich Weiß, Universitätsmedizin Greifswald, Klinik und Poliklinik für Innere Medizin A</p>
        <p>Prof. Dr. Elke Krüger, Universitätsmedizin Greifswald, Institut für Medizinische Biochemie und Molekularbiologie</p>
        <p>Prof. Dr. Dr. Andreas Hermann, Universitätsmedizin Rostock, Abrecht-Kossel Institut für Neuroregeneration</p>
        <p>Prof. Dr. Mihaela Decea, Universität Greifswald, Zentrum für Innovationskompetenz-Nanostrukturen</p>
        <p>Prof. Dr. Udo Kragl, Universität Rostock, Institut für Chemie, Analytische und Technische Chemie</p>
        <p>Prof. Dr. Matthias Beller, Leibniz-Institut für Katalyse e.V., Programmbereich Angewandte Homogenkatalyse</p>
        <p>Prof. Dr. Dr. Sabine Salloch, Universitätsmedizin Greifswald, Institut für Ethik und Geschichte der Medizin,</p>
        <p>PD Dr. Peter Simon, Klinik und Poliklinik für Innere Medizin A</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

