const questions = [
  {
    question: 'Um welche Menge Kohlendioxid könnten die Treibhausgasemissionen in Mecklenburg-Vorpommern durch die Wiedervernässung der Moore reduziert werden?',
    answers: [
      {
        answer: 'drei Megatonnen CO2 pro Jahr',
        correct: true
      },
      {
        answer: 'eine Megatonne CO2 pro Jahr',
        correct: false
      },
      {
        answer: 'fünf Megatonnen CO2 pro Jahr',
        correct: false
      }
    ]
  },
  {
    question: 'Wie viel Prozent der Schwangeren weisen eine psychische Diagnose auf?',
    answers: [
      {
        answer: '43,6 %',
        correct: true
      },
      {
        answer: '15,7 %',
        correct: false
      },
      {
        answer: '30,8 %',
        correct: false
      }
    ]
  },
  {
    question: 'Wie viele schwangere Frauen gibt es jährlich in MV?',
    answers: [
      {
        answer: 'ca. 13.000',
        correct: true
      },
      {
        answer: 'ca. 130.000',
        correct: false
      },
      {
        answer: 'ca. 5.000',
        correct: false
      }
    ]
  },
  {
    question: 'Welche Kostform unterstützt den Darm bei seiner Verdauungstätigkeit?',
    answers: [
      {
        answer: 'Ballaststoffreiche Nahrung',
        correct: true
      },
      {
        answer: 'Trennkost',
        correct: false
      },
      {
        answer: 'Milchfreie Kost',
        correct: false
      }
    ]
  },
  {
    question: 'Was kann mithilfe der sogenannten Bioimpedanz-Analyse (BIA) bestimmt werden?',
    answers: [
      {
        answer: 'Die Körperzusammensetzung',
        correct: true
      },
      {
        answer: 'Die Länge des Darmes',
        correct: false
      },
      {
        answer: 'Die Anzahl roter Blutkörperchen',
        correct: false
      }
    ]
  },
  {
    question: 'Wozu wird die Gallenflüssigkeit oder die Galle benötigt?',
    answers: [
      {
        answer: 'Zur Verdauung von Fetten',
        correct: true
      },
      {
        answer: 'Zur Verdauung von Eiweißen bzw. Proteinen',
        correct: false
      },
      {
        answer: 'Zur Verdauung von Zuckern bzw. Kohlenhydraten',
        correct: false
      }
    ]
  },
  {
    question: 'Warum haben künstliche Herzklappenprothesen ein hohes Marktpotenzial?',
    answers: [
      {
        answer: 'Weil der Bedarf bedingt durch den demografischen Wandel zukünftig steigt.',
        correct: true
      },
      {
        answer: 'Weil die Entwicklung nicht aufwendig ist.',
        correct: false
      },
      {
        answer: 'Weil es sich um Nischenprodukte handelt.',
        correct: false
      }
    ]
  },
  {
    question: 'In Card-ii-Omics werden neuartige Herzklappenprothesen entwickelt. Welchen Wettbewerbsvorteil hätten diese gegenüber derzeit verfügbaren Produkten?',
    answers: [
      {
        answer: 'Sie vermindern das Risiko einer kardiovaskulären Implantatinfektion.',
        correct: true
      },
      {
        answer: 'Sie können ohne ärztliche Hilfe implantiert werden.',
        correct: false
      },
      {
        answer: 'Die Fertigungskosten sind deutlich geringer.',
        correct: false
      }
    ]
  },
  {
    question: 'Welches ist die häufigste Krebserkrankung in Deutschland?',
    answers: [
      {
        answer: 'Hautkrebs',
        correct: true
      },
      {
        answer: 'Darmkrebs',
        correct: false
      },
      {
        answer: 'Blutkrebs',
        correct: false
      }
    ]
  },
  {
    question: 'Welche neuartige Therapieform will ONKOTHER-H zur Behandlung bei Hautkrebs ergänzend einsetzen?',
    answers: [
      {
        answer: 'kaltes Atmosphärendruckplasma & small molecules',
        correct: true
      },
      {
        answer: 'heißes Atmosphärendruckplasma & small molecules',
        correct: false
      },
      {
        answer: 'kaltes Atmosphärendruckplasma & large molecules',
        correct: false
      }
    ]
  },
  {
    question: 'Welche Aufgabe hat das Endoplasmatische Retikulum (ER)?',
    answers: [
      {
        answer: 'am oder im ER finden Translation, Proteinfaltung und Proteinqualitätskontrolle statt',
        correct: true
      },
      {
        answer: 'das ER ist verantwortlich für Leber- und Bauchspeicheldrüsenerkrankungen',
        correct: false
      },
      {
        answer: 'durch das weit verzweigte Membrannetzwerk aus Röhren, Bläschen und Zisterne werden Blutkörperchen transportiert',
        correct: false
      }
    ]
  },
  {
    question: 'Welche Krankheiten können durch ER-Stress begünstigt werden?',
    answers: [
      {
        answer: 'Leber- und Bauchspeicheldrüsenerkrankungen',
        correct: true
      },
      {
        answer: 'Erkrankungen des Herz-Kreislaufsystems',
        correct: false
      },
      {
        answer: 'Psychische Erkrankungen',
        correct: false
      }
    ]
  },
  {
    question: 'Welches Tier eignet sich am besten als humannahes biomedizinisches Infektionsmodell?',
    answers: [
      {
        answer: 'das Schwein',
        correct: true
      },
      {
        answer: 'das Rind',
        correct: false
      },
      {
        answer: 'der Hund',
        correct: false
      }
    ]
  },
  {
    question: 'Was bedeutet der Begriff „Koinfektion“?',
    answers: [
      {
        answer: 'Infektion eines Organismus mit zwei Krankheitserregern gleichzeitig',
        correct: true
      },
      {
        answer: 'gleichzeitige Infektion mehrerer Organe',
        correct: false
      },
      {
        answer: 'eine sehr hohe Ansteckungsgefahr',
        correct: false
      }
    ]
  },
  {
    question: 'Was ist allogenes Gewebe?',
    answers: [
      {
        answer: 'körperfremdes Gewebe',
        correct: true
      },
      {
        answer: 'körpereigenes Gewebe',
        correct: false
      },
      {
        answer: 'pflanzliches Gewebe',
        correct: false
      }
    ]
  },
  {
    question: 'Wofür wird die HHD-Technologie in HOGEMA eingesetzt?',

    answers: [
      {
        answer: 'zur Aufbereitung von humanen Allograft-Transplantaten',
        correct: true
      },
      {
        answer: 'zur Zerstörung des allogenen Gewebes',
        correct: false
      },
      {
        answer: 'zur Umwandlung von allogenem Gewebe in körpereigenes Gewebe',
        correct: false
      }
    ]
  },
  {
    question: 'Wie kann man die Menge der RNA quantifizieren?',
    answers: [
      {
        answer: 'mit Microarrays und RNA Sequenzierung',
        correct: true
      },
      {
        answer: 'mit einem Massenspektrometer',
        correct: false
      },
      {
        answer: 'mit einem Blutdruckmessgerät',
        correct: false
      }
    ]
  },
  {
    question: 'Welches ist die häufigste Todesursache in Deutschland?',
    answers: [
      {
        answer: 'Herz-Kreislauferkrankungen',
        correct: true
      },
      {
        answer: 'Krebserkrankungen',
        correct: false
      },
      {
        answer: 'Infektionserkrankungen',
        correct: false
      }
    ]
  },
  {
    question: 'Welches Testverfahren wird zur Generierung und Etablierung programmierter Herzschrittmacher verwendet?',
    answers: [
      {
        answer: 'In vitro Tests',
        correct: true
      },
      {
        answer: 'In vivo Tests',
        correct: false
      },
      {
        answer: 'Simulative Testverfahren',
        correct: false
      }
    ]
  },
  {
    question: 'Welchen Durchmesser haben die Leitungsbahnen (Tracheen) von Laubbäumen?',
    answers: [
      {
        answer: 'bis 500 µm',
        correct: true
      },
      {
        answer: '5 bis 500 nm',
        correct: false
      },
      {
        answer: '5 bis 500 mm',
        correct: false
      }
    ]
  },
  {
    question: 'Wie viele Mücken kann eine Fledermaus in einer Stunde fressen?',
    answers: [
      {
        answer: 'ca. 1000',
        correct: true
      },
      {
        answer: 'ca. 2000',
        correct: false
      },
      {
        answer: 'ca. 4000',
        correct: false
      }
    ]
  },
  {
    question: 'Wie alt ist die älteste bekannte Fledermaus?',
    answers: [
      {
        answer: '41 Jahre',
        correct: true
      },
      {
        answer: '21 Jahre',
        correct: false
      },
      {
        answer: '61 Jahre',
        correct: false
      }
    ]
  },
  {
    question: 'Bilden Pflanzen mehr Biomasse oberhalb oder unterhalb der Bodenoberfläche?',
    answers: [
      {
        answer: 'ober- und unterirdische Biomasse ist annähernd gleich',
        correct: true
      },
      {
        answer: 'mehr Biomasse oberhalb der Bodenoberfläche',
        correct: false
      },
      {
        answer: 'mehr Biomasse unterhalb der Bodenoberfläche (Wurzeln)',
        correct: false
      }
    ]
  },
  {
    question: 'Weltweit betrachtet hat sich die Anzahl der Menschen, die bereits einen Schlaganfall erlitten haben, in den Jahren 2005 bis 2015 ...',
    answers: [
      {
        answer: 'um 21,8 % erhöht',
        correct: true
      },
      {
        answer: 'um 5,2 % verringert',
        correct: false
      },
      {
        answer: 'um 8,9 % erhöht',
        correct: false
      }
    ]
  },
  {
    question: 'Schlaganfälle bewirken oftmals ...',
    answers: [
      {
        answer: 'Sprachstörungen',
        correct: true
      },
      {
        answer: 'Schlafstörungen',
        correct: false
      },
      {
        answer: 'Geschmacksstörungen',
        correct: false
      }
    ]
  },
  {
    question: 'Digitalisierung der Pflege bedeutet:',
    answers: [
      {
        answer: 'Die Daten der Pflegenden werden digital erfasst und verwaltet',
        correct: true
      },
      {
        answer: 'Ältere Menschen lernen den Umgang mit Computern',
        correct: false
      },
      {
        answer: 'Ältere Menschen kommunizieren nur noch digital mit dem Pflegepersonal',
        correct: false
      }
    ]
  },
  {
    question: 'Intelligentes Tutoring heißt:',
    answers: [
      {
        answer: 'Das Computerlernprogramm stellt sich auf die Bedürfnisse jedes Lernenden individuell ein',
        correct: true
      },
      {
        answer: 'Die Tutorinnen und Tutoren müssen einen Intelligenztest bestehen',
        correct: false
      },
      {
        answer: 'Die Schülerinnen und Schüler werden auf einen Intelligenztest vorbereitet',
        correct: false
      }
    ]
  },
  {
    question: 'Eine Altenpflegerin / ein Altenpfleger verdient durchschnittlich im Monat',
    answers: [
      {
        answer: '2.500 Euro',
        correct: true
      },
      {
        answer: '3.500 Euro',
        correct: false
      },
      {
        answer: '4.500 Euro',
        correct: false
      }
    ]
  },
  {
    question: 'Wieviel Prozent der Landfläche von Mecklenburg-Vorpommern sind von Mooren bedeckt?',
    answers: [
      {
        answer: '13 %',
        correct: true
      },
      {
        answer: '3 %',
        correct: false
      },
      {
        answer: '23 %',
        correct: false
      }
    ]
  },
  {
    question: 'Weltweit betrachtet hat sich die Anzahl der Menschen, die aufgrund eines Schlaganfalls mit schweren alltagsrelevanten Behinderungen leben müssen, in den Jahren 2005 bis 2015 ...',
    answers: [
      {
        answer: 'um 22 % erhöht',
        correct: true
      },
      {
        answer: 'um 11 % erhöht',
        correct: false
      },
      {
        answer: 'um 33 % erhöht',
        correct: false
      }
    ]
  },
]

module.exports = {
  questions
}
