<template>
  <div class="content">
    <main>
      <div class="stage">
      <swiper
          :navigation="{ nextEl: '.next', prevEl: '.prev', disabledClass: 'hidden' }"
          :pagination="{ clickable: true }"
          @slideChange="onSlideChange">
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/DigiCare_Martens_neu.mp4#t=0.3`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Prof. Dr. Alke Martens, Sprecherin des Forschungsverbundes DigiCare</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <video :src="`${publicPath}static/video/DigiCare_Vogel_neu.mp4#t=0.1`" controls disablepictureinpicture controlslist="nodownload" preload="metadata"></video>
            <div class="caption">Jann Niklas Vogel, Doktorand im Projekt DigiCare</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/DigiCare_Alke_Robot.jpg`">
            <div class="caption">Durch digitale Anwendungen und neue Technologien soll die Ausbildung im Pflege- und Gesundheitsmanagement verbessert werden / Bildnachweis: Universität Rostock</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/DigiCare_Kickoff.jpg`">
            <div class="caption">Gruppenbild der Projektpartner / Bildnachweis: Hochschule Neubrandenburg</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/DigiCare_Konrad_Zuse_Haus_Foyer.jpg`">
            <div class="caption">Lobby des Konrad-Zuse-Hauses der Universität Rostock / Bildnachweis: Universität Rostock</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/DigiCare_Konrad_Zuse_Haus.jpg`">
            <div class="caption">Außenansicht des Konrad-Zuse-Hauses der Universität Rostock / Bildnachweis: Universität Rostock</div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <img :src="`${publicPath}static/img/DigiCare_Grafik.jpg`">
            <div class="caption">Bildnachweis: DigiCare /  Projektträger Jülich, Forschungszentrum Jülich GmbH</div>
          </div>
        </swiper-slide>
      </swiper>
      <Prev class="prev" />
      <Next class="next" />
      </div>
      <section>
        <h1>DigiCare - Digitales Trainieren von Pflege- und Gesundheitsmanagement</h1>
        <p>Für die Versorgung einer immer älter werdenden Bevölkerung ist ein gut qualifiziertes Personal erforderlich. Mit dem Projekt <b>DigiCare</b> soll die Ausbildung im Bereich Pflege- und Gesundheitsmanagement durch die Einbeziehung digitaler Anwendungen verbessert werden. Dazu werden digitale Lehr- und Lernformate für Gesundheits- und Pflegestudiengänge entwickelt. Die Forschenden erarbeiten u. a. klinische Lehrfälle, die das Erlernen pflegerischer und medizinischer Praxisprozesse erleichtern sollen und zugleich Rückschlüsse für die Lehre in den Studiengängen des Gesundheits- und Pflegemanagements erlauben.</p>
        <p>Digitalisierung in der Lehre und Ausbildung bedeutet nicht nur die Nutzung neuer Technologien in der Wissensvermittlung, sondern erfordert gleichzeitig eine Verhaltensänderung von Lehrenden und Lernenden. So wird der Handlungsbereich der Studierenden im eigentlichen Unterrichtsgeschehen erweitert, in dem die Methoden der semantischen Annotation von Vorlesungen als didaktisches Mittel eingesetzt und erprobt werden. Die daraus resultierenden semantischen Netze werden Dozenten zur Selbstreflektion über ihr Lehrmaterial und Studierenden zur kritischen Reflektion des eigenen Lernstands zur Verfügung gestellt.</p>
        <p>In einem ersten Schritt soll die stattfindende Präsenzlehre aufgezeichnet und aufbereitet werden, so dass am Ende des Projektes Lehrformate vorliegen, die sowohl unterstützend in der Präsenzlehre, als auch in der Distanzlehre eingesetzt werden können. Im Ergebnis daraus soll ein neuer Studiengang entwickelt werden, der dazu beiträgt, die Herausforderungen der Digitalisierung im Bereich Pflege und Gesundheit mitzugestalten. Am Ende des Projekts liegen Lehrformate vor, die sowohl die Präsenz- als auch die Distanzlehre unterstützen sollen.</p>
        <p>Parallel zu der Digitalisierung der Vorlesungen wird im Projekt die Ebene des fallbasierten Lernens im Studiengang etabliert. Diese Methode ist bisher unterrepräsentiert. Entsprechend der Lehrinhalte im Studiengang werden zwei Handlungsräume für die konkreten Lehrfälle herangezogen: der Umgang mit Patienten im Pflegekontext (Praxisnähe) und die Managementtätigkeit (Dialogübungen). In beiden Fällen wird auf die Technologie eines fallbasierten intelligenten Tutoring Systems zurückgegriffen. Hierfür muss eine Expertise für das Anwendungsgebiet entwickelt werden, sowie mittels künstlicher Intelligenz eine Analyse von Dialogen erfolgen. Da die Erstellung von Lehrfällen aufwändig ist, werden die automatische Generierung von Dialogen aus Eckdaten des Expertensystems sowie die automatische maschinelle Analyse der Eingabe des Studierenden untersucht.</p>
        <p>In diesem Forschungsverbund arbeiten Wissenschaftlerinnen und Wissenschaftler der Universität Rostock, der Hochschule Neubrandenburg und des Deutschen Zentrums für Neurodegenerative Erkrankungen e. V. (DZNE) transdisziplinär zusammen. Unter Leitung der Nachwuchsgruppenleiterin Frau Dr. Kristina Yordanova werden Promovierende aus den Fachbereichen Informatik, Gesundheitsmanagement, Klinische Demenzforschung und Bibliothekswesen gemeinsam die Fragestellungen diskutieren und Lösungsansätze formulieren. 23 Studierende aus diesen Fachbereichen werden innerhalb des Verbundes die Möglichkeit erhalten, ihre Masterarbeit anzufertigen und so bereits in ihrer eigenen Ausbildung mit diesen neuen Lehrmethoden vertraut gemacht.</p>
      </section>
    </main>
    <aside :class="{ active: sidebar }">
      <div class="sidebar">
        <img :src="`${publicPath}static/img/DigiCare_Vorlesungsaufzeichnung.jpg`">
        <div class="caption">Präsentation des Projekts DigiCare / Bildnachweis: Universität Rostock</div>
        <h3>Projekttitel:</h3>
        <h2>Digitales Trainieren von Pflege- und Gesundheitsmanagement</h2>
        <h3>Fördersumme:</h3>
        <p>ca. 2,3 Mio. Euro</p>
        <h3>Laufzeit:</h3>
        <p>01.07.2019 – 30.09.2022</p>
        <h3>Koordinator des Verbundes:</h3>
        <p>Prof. Alke Martens, Universität Rostock, Institut für Informatik, Lehrstuhl für Praktische Informatik</p>
        <h3>Projektpartner:</h3>
        <p>Prof. Alke Martens, Universität Rostock, Institut für Informatik, Lehrstuhl für Praktische Informatik</p>
        <p>Prof. Stefan Schmidt, Hochschule Neubrandenburg, Fachbereich Gesundheit, Pflege, Management</p>
        <p>Prof. Thomas Kirste, Universität Rostock, Institut für Visual and Analytic Computing, Lehrstuhl für Mobile Multimediale Informationssysteme</p>
        <p>Prof. Stefan Teipel, Deutsches Zentrum für Neurodegenerative Erkrankungen DZNE, Standort Rostock/Greifswald, Klinische Demenzforschung</p>
        <p>Renate Bähker, Universität Rostock, Universitätsbibliothek</p>
      </div>
      <div
          :class="{ active: sidebar, toggle: true }"
          @click="sidebar = !sidebar">
        <svg viewBox="0 0 15 26">
          <polygon points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5" />
        </svg>
      </div>
    </aside>
  </div>
</template>


<script>
import { ref } from 'vue'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import Prev from '@/components/Prev'
import Next from '@/components/Next'
import '@/assets/scss/_overlayContent.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Prev,
    Next
  },

  setup: () => {
    const publicPath = process.env.BASE_URL
    const sidebar = ref(false)
    const onSlideChange = () => document.querySelectorAll('video').forEach(v => v.pause())

    return {
      publicPath, sidebar, onSlideChange
    }
  }
}
</script>

