<template>
  <div
      class="bubble-container"
      :style="styles">
    <div
        class="bubble-parent"
        :style="{ animationDuration: `${motherBubble.floatDuration}s` }">
      <img :src="motherBubble.img">
      <div>
        <small
            v-if="motherBubble.bubbles">
          Projekte
        </small>
        <span
            class="title"
            v-html="motherBubble.title" />
      </div>
    </div>
  </div>
</template>


<script>
import { computed } from 'vue'

export default {
  name: 'MotherBubble',

  props: {
    motherBubble: Object
  },

  setup(props) {
    const styles = computed(() => {
      return {
        left: `${props.motherBubble.x}px`,
        top: `${props.motherBubble.y}px`,
        width: `${props.motherBubble.radius * 2}px`,
        height: `${props.motherBubble.radius * 2}px`
      }
    })
    
    return {
      styles
    }
  }
}
</script>


<style scoped lang="scss">
.bubble-container {
  position: absolute;
  pointer-events: none;
}

.bubble-parent {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: linear-gradient(to top, #09203f 0%, #2c8eda 100%);
  box-shadow: 0 0 0 0.3vw rgba($color: #fff, $alpha: 0.4), 0 0 0 1vw rgba($color: #fff, $alpha: 0.2), 0 0 30px rgba($color: #000, $alpha: 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }

  div {
    backdrop-filter: blur(5px);
    padding: 0.9vh 0.75vw;
    border-radius: 1vw;
    border: 1px solid rgba($color: #fff, $alpha: 0.3);
    pointer-events: all;
    cursor: pointer;
    transition: border 250ms ease-in-out;

    &:hover {
      border: 1px solid rgba($color: #fff, $alpha: 1);
      box-shadow: 0 0 1.5rem rgba($color: #000, $alpha: 0.25);
    }
  }

  small {
    position: relative;
    z-index: 10;
    display: block;
    font-weight: 700;
    color: #06003b;
    font-size: calc(0.25rem + 0.5vw);
    margin-bottom: 0.2rem;
    text-transform: uppercase;
  }

  span {
    position: relative;
    z-index: 10;
    letter-spacing: -1px;
    font-size: calc(0.5rem + 1vw);
    font-weight: 500;
    color: #fff;
    // text-shadow: 1px 1px 1px rgba($color: #000, $alpha: 0.15);
  }
}
</style>
